import React, { useState, useEffect } from 'react';
import {
  EuiFlexGroup,
  EuiFlexItem,
  EuiSideNav,
  EuiPanel,
  EuiButton,
  EuiText
} from '@elastic/eui';
import SongManagement from './SongManagement';  // 导入诗歌管理组件
import UserManagement from './UserManagement';
import RadioManagement from './RadioPageWithPlayer';
import VerseManagement from './VerseManagement';
import SermonManagement from './SermonManagement';
import NetworkManagement from './NetworkManagement';
import PrayManagement from './PrayManagement';
import DataAnalysis from './DataAnalysis';
import { useNavigate } from 'react-router-dom';
import { jwtDecode } from "jwt-decode";

const AdminDashboard = () => {
  // 维护当前选择的页面
  const [selectedPage, setSelectedPage] = useState('用户管理');
  const [isAdmin, setIsAdmin] = useState(false); // 用于判断用户是否是管理员
  const navigate = useNavigate(); // 使用 useNavigate 来导航

  useEffect(() => {
    const token = sessionStorage.getItem("token");  // 从 localStorage 中获取 token
    let userRole = "";
    if (token) {
        const decodedToken = jwtDecode(token);  // 解码 token
        // console.log(decodedToken);  // 检查 token 中的内容，包括角色信息
        userRole = decodedToken.role;  // 获取角色信息
    }
    if (userRole === 'Admin') {
      setIsAdmin(true); // 如果用户角色是管理员，允许访问
    } else {
      setIsAdmin(false); // 如果不是管理员，阻止访问
    }
  }, []);

  // 如果用户不是管理员，重定向到其他页面或显示错误信息
  if (!isAdmin) {
    return (
      <EuiText color="danger">
        您没有访问此页面的权限。请联系管理员。
      </EuiText>
    );
  }

  // 导航项配置
  const sideNav = [
    {
      name: '管理选项',
      id: 'root',
      items: [
        {
          name: '用户管理',
          id: 'users',
          onClick: () => setSelectedPage('用户管理'),
        },
        {
          name: '诗歌管理',
          id: 'songs',
          onClick: () => setSelectedPage('诗歌管理'),
        },
        {
          name: '电台管理',
          id: 'radio',
          onClick: () => setSelectedPage('电台管理'),
        },
        {
          name: '经文管理',
          id: 'scripture',
          onClick: () => setSelectedPage('经文管理'),
        },
        {
          name: '信息管理',
          id: 'info',
          onClick: () => setSelectedPage('信息管理'),
        },
        {
          name: '網路管理',
          id: 'info',
          onClick: () => setSelectedPage('網路管理'),
        },
        {
          name: '禱告管理',
          id: 'info',
          onClick: () => setSelectedPage('禱告管理'),
        },
        {
          name: '数据分析',
          id: 'info',
          onClick: () => setSelectedPage('数据分析'),
        },
      ],
    },
  ];

  // 渲染右侧内容，根据不同的页面展示内容
  const renderContent = () => {
    switch (selectedPage) {
      case '用户管理':
        return <UserManagement />;
      case '诗歌管理':
        return <SongManagement />;
      case '电台管理':
        return <RadioManagement />;
      case '经文管理':
        return <VerseManagement />;
      case '信息管理':
        return <SermonManagement />;
      case '網路管理':
        return <NetworkManagement />;
      case '禱告管理':
        return <PrayManagement />;
      case '数据分析':
          return <DataAnalysis />;
      default:
        return <div>请选择一个管理选项。</div>;
    }
  };

  // 返回主页的函数
  const handleBackToHome = () => {
    navigate('/');
  };

  return (
    <EuiFlexGroup gutterSize="none">
      {/* 左侧导航栏 */}
      <EuiFlexItem grow={false} style={{ minWidth: 150 }}>
        <EuiSideNav
          items={sideNav}
          mobileTitle="管理导航"
          style={{ width: 150 }}
        />
        {/* 返回主页按钮 */}
        <div style={{ padding: '20px' }}>
          <EuiButton fill onClick={handleBackToHome}>返回主页</EuiButton>
        </div>
      </EuiFlexItem>

      {/* 右侧内容区域 */}
      <EuiFlexItem>
        <EuiPanel paddingSize="l">
          {renderContent()}
        </EuiPanel>
      </EuiFlexItem>
    </EuiFlexGroup>
  );
};

export default AdminDashboard;
