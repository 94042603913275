import React, { useState, useEffect, useRef } from 'react';
import { EuiFlexGroup, EuiFlexItem, EuiIcon, EuiPanel, EuiImage, EuiButton, EuiListGroup, EuiListGroupItem, EuiText, EuiToolTip, EuiPopover } from '@elastic/eui';
import ReactPlayer from 'react-player';
import radio1_img from '../assets/radio1_1.png';
import radio2_img from '../assets/radio2_1.png';
import radio3_img from '../assets/radio3_1.png';
import radio4_img from '../assets/radio4_1.png';
import radio5_img from '../assets/radio5_1.png';
import radio6_img from '../assets/radio6_1.png';
import radio7_img from '../assets/radio7_1.png';
import radio8_img from '../assets/radio8_1.png';
import radio9_img from '../assets/radio9_1.png';
import defaultRadioImg from '../assets/radio0_1.png';
import sortup_icon from '../assets/sortup.png';
import shuffle_icon from '../assets/shuffle.png';
import repeat_icon from '../assets/repeat.png';
import {TranslateLanguage} from '../components/KeywordTranslator';
import { useNavigate } from 'react-router-dom';
import PlaylistModal from "../components/PlaylistModal"
import logosmall from '../assets/CTBLogoCircle-Small.png';
import headphone_icon from '../assets/headphone2.png';
import fullscreen_icon from '../assets/fullscreen.png';
import axios from 'axios';
import { useAlert } from './AlertProvider';

const language = localStorage.userLanguage;

const radioStations = [
  { id: 1, name: TranslateLanguage(localStorage.userLanguage, "喜樂"), imgSrc: radio1_img, playcount: 0 },
  { id: 2, name: TranslateLanguage(localStorage.userLanguage, "平安"), imgSrc: radio2_img, playcount: 0 },
  { id: 3, name: TranslateLanguage(localStorage.userLanguage, "渴慕"), imgSrc: radio3_img, playcount: 0 },
  { id: 4, name: TranslateLanguage(localStorage.userLanguage, "盼望"), imgSrc: radio4_img, playcount: 0 },
  { id: 5, name: TranslateLanguage(localStorage.userLanguage, "感恩"), imgSrc: radio5_img, playcount: 0 },
  { id: 6, name: TranslateLanguage(localStorage.userLanguage, "仁愛"), imgSrc: radio6_img, playcount: 0 },
  { id: 7, name: TranslateLanguage(localStorage.userLanguage, "信心"), imgSrc: radio9_img, playcount: 0 },
  { id: 8, name: TranslateLanguage(localStorage.userLanguage, "禱告"), imgSrc: radio7_img, playcount: 0 },
  { id: 9, name: TranslateLanguage(localStorage.userLanguage, "敬拜"), imgSrc: radio8_img, playcount: 0 },
];

function App() {
  const [selectedStation, setSelectedStation] = useState(null);
  const [currentSong, setCurrentSong] = useState(null);
  const [initSong, setInitSong] = useState(null);
  const [radiolists, setRadiolists] = useState([]);
  const [songs, setSongs] = useState([]);
  const [playMode, setPlayMode] = useState('sequence'); // 'sequence', 'repeat', 'shuffle'
  const [playing, setPlaying] = useState(false);
  const [showPlaylistModal, setShowModal] = useState(false);
  const [modalProps, setModalProps] = useState({
    userId: '',
    songId: '',
    videoURL: '',
    type: '',
    engineId: '',
    title: '',
    label: '',
    playlisttype: ''
  });
  const navigate = useNavigate();
  const [ytApiReady, setYtApiReady] = useState(false);
  const playerRef = useRef(null); // 用 useRef 来存储 player 实例
  const currentSongRef = useRef(currentSong);   // 使用 useRef 存储最新的 currentSong
  const songsRef = useRef(songs);
  const playModeRef = useRef(playMode);
  const [showHint, setShowHint] = useState(true); // 提示文字的显示状态
  const [stationName, setStationName] = useState(null);
  const [wasInFullScreen, setwasInFullScreen] = useState(false);
  const showAlert = useAlert();
  const [isPopoverModeOpen, setIsPopoverModeOpen] = useState(false);
  const [isPopoverFullScreenOpen, setIsPopoverFullScreenOpen] = useState(false);
  const [isPopoverAddOpen, setIsPopoverAddOpen] = useState(false);
  const modeLabels = {
    sequence: '列表循環',
    repeat: '單曲循環',
    shuffle: '隨機播放',
  };
  const [showTooltips, setShowTooltips] = useState({
    playMode: true,
    fullscreen: true,
    addToList: true
  });

  const isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
  
  useEffect(() => {
    // 根据是否为 iOS 设备设置提示文字的显示状态
    if (isIOS) {
      setShowHint(true);  // 如果是 iOS 设备，显示提示文字
    } else {
      setShowHint(false); // 如果不是 iOS 设备，隐藏提示文字
    }
    const script = document.createElement('script');
    script.src = "https://www.youtube.com/iframe_api";
    script.async = true;
    script.onload = () => {
      // console.log("YouTube IFrame API script loaded");
  
      // Check if the YT object is already available
      if (window.YT && window.YT.Player) {
        // console.log("YT object is available, API is ready");
        setYtApiReady(true);
      }
    };
    document.body.appendChild(script);
  
    window.onYouTubeIframeAPIReady = () => {
      // console.log("YouTube IFrame API is ready");
      setYtApiReady(true);
    };
  
    // Fallback if the API doesn't signal readiness
    setTimeout(() => {
      if (window.YT && window.YT.Player && !ytApiReady) {
        console.log("Fallback: YT object available, setting ytApiReady to true");
        setYtApiReady(true);
      }
    }, 5000); // Fallback after 5 seconds
  }, []);

  // 当 ytApiReady 和 currentSong 都准备好时初始化播放器
  useEffect(() => {
    if (ytApiReady && initSong && !playerRef.current) {
      playerRef.current = new YT.Player('youtube-player', {
        height: '80%',
        width: '90%',
        videoId: initSong.resourceId,
        playerVars: {
          'autoplay': isIOS ? 0 : 1, // iOS 不自动播放，其他设备自动播放
          'controls': 1, // 显示控制条
          'mute': isIOS ? 1 : 0, // iOS 静音，其他设备不静音
        },
        events: {
          'onStateChange': onPlayerStateChange
        }
      });
    }
  }, [ytApiReady, initSong]);

  useEffect(() => {
    // 当条件满足时，开始定时器
    if (radiolists.length > 0 && initSong && ytApiReady) {
      const timer = setTimeout(() => {
        setShowTooltips({
          playMode: false,
          fullscreen: false,
          addToList: false,
        });
      }, 5000); // 5 秒后隐藏

      // 清理定时器
      return () => clearTimeout(timer);
    }
  }, [radiolists, initSong, ytApiReady]); 

  // 同步更新 currentSongRef
  useEffect(() => {
    currentSongRef.current = currentSong;
  }, [currentSong]);

  useEffect(() => {
    songsRef.current = songs;
  }, [songs]);

  useEffect(() => {
    playModeRef.current = playMode;
  }, [playMode]);

  useEffect(() => {
    if (playerRef.current && currentSong) {
      if (playerRef.current.getPlayerState && playerRef.current.getPlayerState() !== -1) {
        playerRef.current.loadVideoById(currentSong.resourceId);
        if (isIOS){
          playerRef.current.unMute(); // 解除静音
          setShowHint(false); // 隐藏提示文字
        }
    }
    }
  }, [currentSong]);

  // 检查是否处于全屏模式（包括 iOS 设备）
  function isFullScreen() {
    return (
        document.fullscreenElement ||
        document.webkitFullscreenElement || // iOS Safari
        document.mozFullScreenElement ||
        document.msFullscreenElement
    );
  }

  // 添加全屏事件监听器，适用于所有浏览器，包括 iOS
  function addFullScreenEventListener(callback) {
    document.addEventListener('fullscreenchange', callback);
    document.addEventListener('webkitfullscreenchange', callback); // iOS Safari
    document.addEventListener('mozfullscreenchange', callback);
    document.addEventListener('MSFullscreenChange', callback);
  }

  // 示例：监听全屏切换
  addFullScreenEventListener(() => {
    if (isFullScreen()) {
        // console.log("Entered fullscreen");
        setwasInFullScreen(true);
    } else {
        // console.log("Exited fullscreen");
        setwasInFullScreen(false);
    }
});

  function enterFullscreen() {
    const playerElement = playerRef.current.getIframe();
    if (isIOS) {
      showAlert(TranslateLanguage(localStorage.userLanguage, "在 iOS 設備上，請使用播放器右下角的全屏按鈕進入全屏模式。"));
    } else if (playerElement.requestFullscreen) {
        playerElement.requestFullscreen();
    } else if (playerElement.mozRequestFullScreen) { // Firefox
        playerElement.mozRequestFullScreen();
    } else if (playerElement.webkitRequestFullscreen) { // Chrome, Safari and Opera
        playerElement.webkitRequestFullscreen();
    } else if (playerElement.msRequestFullscreen) { // IE/Edge
        playerElement.msRequestFullscreen();
    }
  }

  const onPlayerStateChange = (event) => {
    switch (event.data) {
      case YT.PlayerState.PLAYING:
        setPlaying(true); // 更新状态为播放中
        if (isIOS){
          playerRef.current.unMute(); // 解除静音
          setShowHint(false); // 隐藏提示文字
        }
        break;
      case YT.PlayerState.PAUSED:
        setPlaying(false); // 更新状态为暂停
        break;
      case YT.PlayerState.ENDED:
        handleNextSong();
        break;
      default:
        break;
    }
  };

  function setViewportDimensions() {
    let height = 100;
    let calwidth;
    let calheight;
    calwidth = window.innerWidth*0.6;
    calheight = window.innerHeight - height;
    if (calwidth*9/16 > calheight)
        document.documentElement.style.setProperty('--viewport-height', `${window.innerHeight - height}px`);
    else
        document.documentElement.style.setProperty('--viewport-height', `${calwidth*9/16}px`);
    document.documentElement.style.setProperty('--viewport-width', `${window.innerWidth - 20}px`);
    // document.documentElement.style.setProperty('--viewport-height', `${window.innerHeight - height}px`);
  }

  const loadIcon = (id) => {
    try {
      // 自動生成圖標路徑，根據 id 編號
      return require(`../assets/radio${id}_1.png`);
    } catch (error) {
      return defaultRadioImg; // 若沒有找到圖標文件，回退到默認圖標
    }
  };

  // 初始化时设置值
  setViewportDimensions();
  
  // 监听窗口大小变化事件
  window.addEventListener('resize', setViewportDimensions);

  const SongItem = ({ song, currentSong }) => {
    const songItemRef = useRef(null);
    
    const scrollToSongItem = () => {
      const currentSongElement = document.querySelector('.playing-song'); // 获取当前播放的诗歌元素
      const innerContainer = document.querySelector('.rigth_song_info'); // 内层容器
      const outerContainer = document.documentElement; // 外层容器，指向 <html> 元素
    
      if (currentSongElement && innerContainer) {
        // 假设标题栏的高度为 50px，你可以根据实际情况调整
        const headerHeight = outerContainer.scrollHeight - innerContainer.clientHeight;
    
        // 获取当前诗歌元素相对于内层容器的位置
        const elementTopInInner = currentSongElement.getBoundingClientRect().top - innerContainer.getBoundingClientRect().top + innerContainer.scrollTop;
        
        // 计算内层容器的滚动位置，确保诗歌显示在标题栏下面
        const targetScrollTopInInner = elementTopInInner - headerHeight;
        innerContainer.scrollTo({
          top: targetScrollTopInInner,
          behavior: 'smooth',
        });
    
        // 如果需要，也可以调整外层容器的滚动
        const outerContainerHeight = outerContainer.clientHeight;
        const innerContainerHeight = innerContainer.clientHeight;
        
        // const elementTopInOuter = currentSongElement.getBoundingClientRect().top - outerContainer.getBoundingClientRect().top + outerContainer.scrollTop;
        // const targetScrollTopInOuter = elementTopInOuter - (outerContainerHeight - innerContainerHeight + headerHeight);

        const targetScrollTopInOuter = currentSongElement.getBoundingClientRect().top - headerHeight + outerContainer.scrollTop;
    
        // 延迟滚动外层容器，确保内层容器滚动完成后再进行滚动
        setTimeout(() => {
          outerContainer.scrollTo({
            top: targetScrollTopInOuter,
            behavior: 'smooth',
          });
        }, 300);
      }
    };  

    useEffect(() => {
        if (songItemRef.current && currentSong && currentSong.resourceId === song.resourceId) {
            songItemRef.current.scrollIntoView({
                behavior: 'smooth',
                block: 'center',
            });
            // scrollToSongItem()
        }
    }, [currentSong, song]);

    return (
        <div ref={songItemRef}>
            <EuiListGroupItem
              className={`song-item ${currentSong && currentSong.resourceId === song.resourceId ? 'playing-song' : ''}`}
              key={song.id}
              label={TranslateLanguage(localStorage.userLanguage, song.name)}
              onClick={() => handleSongClick(song)}
              iconType="play"
            />
        </div>
      );
  };

  function formatPlayCount(count) {
    if (count >= 1000000000000) { // 1 兆 or more
      return `${(count / 1000000000000).toFixed(1)}兆`;
    } else if (count >= 100000000) { // 1 亿 or more
      return `${(count / 100000000).toFixed(1)}亿`;
    } else if (count >= 100000) { // 10 万 or more
      return `${(count / 10000).toFixed(1)}万`;
    } else {
      return count.toString(); // Less than 10 万, show the full number
    }
  }   

  useEffect(() => {
    const fetchPlaylistsAndSongs = async () => {
        try {
          // console.log("Fetching playlists...");
          const playlistResponse = await axios.get(`/api/playlists`, {
              params: {
                  userId: 1,
                  type: 'radio'
              }
          });
      
          const playlistData = playlistResponse.data;
          setRadiolists(playlistData);
      
          // if (playlistData && playlistData.length > 0) {
          //     for (let i = 0; i < playlistData.length && i < radioStations.length; i++) {
          //         radioStations[i].id = playlistData[i].id;
          //         radioStations[i].name = playlistData[i].name;
          //         radioStations[i].playcount = playlistData[i].playCount;
          //     }
          // }
          if (playlistData && playlistData.length > 0) {
              // 清空 radioStations
              radioStations.length = 0;

              // 根據 playlistData 動態調整 radioStations
              for (let i = 0; i < playlistData.length; i++) {
                  radioStations.push({
                      id: playlistData[i].id,       // 設置 id
                      name: playlistData[i].name,   // 設置 name
                      playcount: playlistData[i].playCount, // 設置 playcount
                      imgSrc: loadIcon(i+1)  // 根據 id 動態加載圖標
                  });
              }
           }
      
          const randomIndex = Math.floor(Math.random() * radioStations.length);
      
          // 确保所有播放列表ID已经设置完毕后再进行歌曲的请求
          if (radioStations[randomIndex].id) {
              // console.log("Fetching songs for playlist...");
              const songResponse = await axios.get(`/api/playlists/${radioStations[randomIndex].id}/songs`);
      
              setStationName(radioStations[randomIndex].name);
              
              // const sortedSongs = songResponse.data.sort((a, b) => a.order - b.order); // 按 order 排序
              const sortedSongs = songResponse.data.sort(() => 0.5 - Math.random());  // 隨機排序
      
              if (sortedSongs.length > 0) {
                  // console.log("Setting songs and current song...");
                  setSongs(sortedSongs);  // 设置歌曲列表
                  setCurrentSong(sortedSongs[0]);  // 设置当前歌曲
                  setInitSong(sortedSongs[0]);
              } else {
                  console.error("No songs available to set as current song.");
              }
          } else {
              console.error("No radio station ID available to fetch songs.");
          }
      } catch (error) {
          console.error("Error occurred during fetching playlists or songs:", error);
      }
    };

    fetchPlaylistsAndSongs();
  }, []);

    const handleStationClick = async (station) => {
      try {
          const response = await axios.get(`/api/playlists/${station.id}/songs`);
      
          // const sortedSongs = response.data.sort((a, b) => a.order - b.order); // 按 order 排序
          const sortedSongs = response.data.sort(() => 0.5 - Math.random());  // 隨機排序
      
          if (sortedSongs.length > 0) {
              setSongs(sortedSongs);  // 设置歌曲列表
              setSelectedStation(station);
              setStationName(station.name);
              setCurrentSong(sortedSongs[0]);  // 设置当前歌曲
          } else {
              console.error("No songs available to set as current song.");
          }
      } catch (error) {
          console.error("Error fetching songs:", error);
      }
    };

    const handleSongClick = (song) => {
      setCurrentSong(song);
    };

    const handleAddToPlaylist = () => {
      const user = sessionStorage.getItem('user');
      setModalProps({
        userId: JSON.parse(user).id, // 假设 user 对象里有 id 字段
        songId: currentSong.resourceId,
        videoURL: currentSong.link,
        type: '詩歌', 
        engineId: currentSong.engineID, 
        title: currentSong.name,
        label: 'v',
        playlisttype: 'song'
      });
      setShowModal(true);
    };

    const handleBackToStations = () => {
      setSelectedStation(null);
      // setCurrentSong(null);
    };

  const handlePreviousSong = () => {
    if (currentSongRef.current){
      let currentIndex = songs.findIndex((s) => s.resourceId === currentSongRef.current.resourceId);
      let previousIndex;
      switch (playMode) {
        case 'repeat':
            previousIndex = currentIndex;
            playerRef.current.loadVideoById(currentSongRef.current.resourceId);
            if (playerRef.current) {
              playerRef.current.seekTo(0); // 跳到视频开始
              playerRef.current.playVideo(); // 重新播放
            }
            break;
        case 'shuffle':
            previousIndex = Math.floor(Math.random() * songs.length);
            break;
        default:
            previousIndex = (currentIndex - 1 + songs.length) % songs.length;
            break;
    }
    setCurrentSong(songs[previousIndex]);
  }
};

const handleNextSong = () => {
  if (currentSongRef.current) {
    // 获取当前的全屏状态
    // const isFullScreen = document.fullscreenElement || document.webkitFullscreenElement || document.mozFullScreenElement || document.msFullscreenElement;

    let currentIndex = songsRef.current.findIndex((s) => s.resourceId === currentSongRef.current.resourceId);
    // console.log('Current Index:', currentIndex);
    // console.log('Current Song:', currentSongRef.current);

    let nextIndex;
    switch (playModeRef.current) {
      case 'repeat':
        nextIndex = currentIndex;
        playerRef.current.loadVideoById(currentSongRef.current.resourceId);
        if (playerRef.current) {
          playerRef.current.seekTo(0); // 跳到视频开始
          playerRef.current.playVideo(); // 重新播放
        }
        break;
      case 'shuffle':
        nextIndex = Math.floor(Math.random() * songsRef.current.length);
        break;
      default:
        nextIndex = (currentIndex + 1) % songsRef.current.length;
        break;
    }
    // console.log('Next Index:', nextIndex);
    setCurrentSong(songsRef.current[nextIndex]);
  }
};

const handleEnd = () => {
  if (currentSong){
    let currentIndex = songs.findIndex((s) => s.resourceId === currentSong.resourceId);
    let nextIndex;
    switch (playMode) {
        case 'repeat':
            nextIndex = currentIndex;
            setCurrentSong(songs[nextIndex]); // 重新设置当前歌曲
            setPlaying(false); // 停止播放
            setTimeout(() => setPlaying(true), 0); // 触发重新播放
            break;
        case 'shuffle':
            nextIndex = Math.floor(Math.random() * songs.length);
            break;
        default:
            nextIndex = (currentIndex + 1) % songs.length;
            break;
    }
    setCurrentSong(songs[nextIndex]);
  }
};


  const handlePlayModeClick = () => {
    const modes = ['sequence', 'repeat', 'shuffle'];
    const nextMode = modes[(modes.indexOf(playMode) + 1) % modes.length];
    setPlayMode(nextMode);
    setIsPopoverModeOpen(true); // 显示弹窗

    // 设置1秒后自动关闭弹窗
    setTimeout(() => {
      setIsPopoverModeOpen(false);
    }, 1000);
  };

  const getPlayModeIcon = (mode) => {
    switch (mode) {
        case 'sequence':
            return sortup_icon;
        case 'repeat':
            return repeat_icon;
        case 'shuffle':
            return shuffle_icon;
        default:
            return sortup_icon;
    }
  };

  const togglePlayPause = () => {
    if (playerRef.current) {
      if (playing) {
        playerRef.current.pauseVideo();
      } else {
        playerRef.current.playVideo();
        if (isIOS){
          playerRef.current.unMute(); // 解除静音
          setShowHint(false); // 隐藏提示文字
        }
      }
      setPlaying(!playing); // 切换播放状态
    }
  };

  const handleFullScreen = () => {
      enterFullscreen(); // Enter fullscreen mode when video starts
  };
  // 处理播放事件
  const handlePlay = () => {
    setPlaying(true);
  };

  // 处理暂停事件
  const handlePause = () => {
    setPlaying(false);
  };

  const closePopover = () => {
    setIsPopoverModeOpen(false);
  };

  // 显示和隐藏 Popover
  const onMouseEnterFullScreen = () => setIsPopoverFullScreenOpen(true);
  const onMouseLeaveFullScreen = () => setIsPopoverFullScreenOpen(false);

  const onMouseEnterAdd = () => setIsPopoverAddOpen(true);
  const onMouseLeaveAdd = () => setIsPopoverAddOpen(false);

  return (
    <EuiFlexGroup gutterSize="l" className="responsive-flex-group">
      <EuiFlexItem grow={3} style={{ position: 'sticky', top: 0,  zIndex: 100 }} className="left_video_item">
        { radiolists.length > 0 && initSong && ytApiReady ? (
          <EuiPanel           
            style={{
                display: 'flex',
                flexDirection: 'column', // 垂直排列子元素
                justifyContent: 'center', // 水平居中对齐
                alignItems: 'center', // 子元素在垂直方向居中
                padding: '10px', // 可根据需要调整面板的内边距
              }}  
          >
            <h3 style={{ textAlign: 'center', margin: '5px 0' }}>{currentSong ?TranslateLanguage(localStorage.userLanguage, currentSong.name):''}</h3>
            {/* <ReactPlayer 
              key={currentSong ? currentSong.resourceId : 'default'}  // 使用当前歌曲的 ID 作为 key
              className='radio-player'
              url={currentSong ? `https://www.youtube.com/watch?v=${currentSong.resourceId}` : 'https://www.youtube.com/watch?v=lfP-3JLVvaw'} 
              playing={playing} 
              onPlay={handlePlay} 
              onPause={handlePause}  
              onEnded={handleEnd} 
              muted={muted}  // 设置静音
              controls 
              width="90%" 
              height="80%" 
            /> */}
            <div id="youtube-player" className='radio-player'></div> {/* 替换 ReactPlayer */}
            <div style={{ 
                display: 'flex', 
                flexDirection: 'column',  // 垂直布局
                justifyContent: 'center', 
                alignItems: 'center', 
                marginTop: '20px', 
                width: '100%'  // 让按钮组与播放器同宽 
              }}>
                <div style={{ 
                  display: 'flex', 
                  justifyContent: 'space-around', 
                  alignItems: 'center', 
                  width: '100%'  // 让按钮组与播放器同宽 
                }}>
                  {/* 随机/顺序/单曲循环 按钮 */}
                  {/* <button onClick={handlePlayModeClick}>
                    <EuiIcon size="l" type={getPlayModeIcon(playMode)} />
                  </button> */}
                  {/* <EuiToolTip position="top" content={`当前播放模式: ${playMode}`}>
                    <button onClick={onButtonClick} style={{ cursor: 'pointer', border: 'none', background: 'none' }}>
                      <EuiIcon size="l" type={getPlayModeIcon(playMode)} />
                    </button>
                  </EuiToolTip> */}
                  <EuiPopover
                    ownFocus
                    button={
                      <button onClick={handlePlayModeClick} style={{ cursor: 'pointer', border: 'none', background: 'none' }}>
                        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                          <EuiIcon size="l" type={getPlayModeIcon(playMode)} />
                          {showTooltips.playMode && (
                            <div style={{ marginTop: '5px', color: 'red' }}>{TranslateLanguage(language, "切換播放模式")}</div>
                        )}
                      </div>
                      </button>
                    }
                    isOpen={isPopoverModeOpen}
                    closePopover={closePopover}
                  >
                  <div style={{ textAlign: 'center' }}> 
                    {TranslateLanguage(language, modeLabels[playMode])}
                  </div>
                </EuiPopover>

                  {/* 上一首 */}
                  <button onClick={handlePreviousSong}>
                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                      <EuiIcon size="l" type="arrowLeft" />
                        {showTooltips.playMode && (
                          <div style={{ marginTop: '5px', color: 'red' }}>{TranslateLanguage(language, "上一首")}</div>
                      )}
                    </div>
                  </button>

                  {/* 播放/暂停 按钮 */}
                  <button onClick={togglePlayPause}>                  
                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                      <EuiIcon size="l" type={playing ? "pause" : "play"} />
                        {showTooltips.playMode && (
                          <div style={{ marginTop: '5px', color: 'red' }}>{TranslateLanguage(language, "播放/暫停")}</div>
                      )}
                    </div>
                  </button>

                  {/* 下一首 */}
                  <button onClick={handleNextSong}>                  
                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                      <EuiIcon size="l" type="arrowRight" />
                        {showTooltips.playMode && (
                          <div style={{ marginTop: '5px', color: 'red' }}>{TranslateLanguage(language, "下一首")}</div>
                      )}
                    </div>    
                  </button>

                  {/* 全屏按纽 */}                  
                <EuiPopover
                  button={
                    <button
                      onClick={handleFullScreen}
                      onMouseEnter={onMouseEnterFullScreen}
                      onMouseLeave={onMouseLeaveFullScreen}
                      style={{ cursor: 'pointer', border: 'none', background: 'none' }}
                    >
                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                      <EuiIcon size="l" type={fullscreen_icon} />
                      {showTooltips.fullscreen && (
                        <div style={{ marginTop: '5px', color: 'red' }}>{TranslateLanguage(language, "全屏")}</div>
                      )}
                    </div>
                    </button>
                  }
                  isOpen={isPopoverFullScreenOpen}
                  closePopover={onMouseLeaveFullScreen}
                >
                  <div style={{ textAlign: 'center' }}>
                  {TranslateLanguage(language, "全屏")}
                  </div>
                </EuiPopover>

                  {/* 加入歌单按钮 */}
                  <EuiPopover
                    button={
                      <button
                        onClick={handleAddToPlaylist}
                        onMouseEnter={onMouseEnterAdd}
                        onMouseLeave={onMouseLeaveAdd}
                        style={{ cursor: 'pointer', border: 'none', background: 'none' }}
                      >
                        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                          <EuiIcon size="l" type="heart" />
                          {showTooltips.addToList && (
                            <div style={{ marginTop: '5px', color: 'red' }}>{TranslateLanguage(language, "加入歌單")}</div>
                          )}
                        </div>
                      </button>
                    }
                    isOpen={isPopoverAddOpen}
                    closePopover={onMouseLeaveAdd}
                  >
                    <div style={{ textAlign: 'center' }}>
                    {TranslateLanguage(language, "加入歌單")}
                    </div>
                </EuiPopover>
                </div>

                {/* 提示文字 */}
                {showHint && (
                  <div style={{ marginTop: '5px', color: 'red', fontWeight: 'bold', fontSize: '22px' }}>
                    {TranslateLanguage(language, "請點擊播放")}
                  </div>
                )}
              </div>
        </EuiPanel>
        ) : <EuiText>   {TranslateLanguage(language, "正在載入中，請稍後")}...</EuiText>
        }
      </EuiFlexItem>

      <EuiFlexItem grow={1} 
        className="rigth_song_info"
        style={{ height: '100vh', overflowY: 'auto' }}>
        {/* 顶部Logo和标题部分 */}
          <div
            style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                cursor: 'pointer',
                marginBottom: '20px',
                width: '100%'
            }}
            onClick={() => navigate('/')}
          >
          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <img src={logosmall} alt="Logo" style={{ width: '50px', height: '50px', marginRight: '10px' }} />
            <h2 style={{ margin: 0 }}>{stationName? TranslateLanguage(localStorage.userLanguage, "詩歌電台")+"-"+TranslateLanguage(localStorage.userLanguage, stationName):TranslateLanguage(localStorage.userLanguage, "詩歌電台")}</h2>
          </div>
        </div>
        <EuiPanel>
          {selectedStation ? (
            <>
              <EuiButton onClick={handleBackToStations} style={{ marginBottom: '10px' }}>{TranslateLanguage(localStorage.userLanguage, "返回電台列表")}</EuiButton>
              <EuiListGroup>
                {songs.map((song) => (
                    <SongItem
                      key={song.resourceId}
                      song={song}
                      currentSong={currentSong}
                    />
                ))}
              </EuiListGroup>
            </>
          ) : (
            <EuiFlexGroup direction="column" gutterSize="l">
              <div
                style={{
                  display: 'grid',
                  gridTemplateColumns: 'repeat(3, 1fr)',
                  gridGap: '10px',
                  padding: '10px',
                }}
                className="radio-grid"
              >
                {radioStations.map((station) => (
                  <div key={station.id} className="radio-item" style={{ textAlign: 'center' }}>
                    <div
                      className="radio-icon"
                      style={{
                        position: 'relative',
                        overflow: 'hidden',
                        borderRadius: '8px',
                        marginBottom: '0px',
                        cursor: 'pointer',
                        width: '100%',
                        height: 'auto',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                      }}
                      onClick={() => handleStationClick(station)}
                    >
                      <EuiImage
                      size="m"
                      alt={station.name}
                      url={station.imgSrc}
                      style={{
                        width: '100%',
                        height: '100%',
                        objectFit: 'cover', // Use 'cover' to fill the area while preserving aspect ratio
                        borderRadius: '8px',
                      }}
                    />
                    <div className="play-count" style={{
                      position: 'absolute',
                      top: '5px',
                      left: '5px',
                      backgroundColor: 'rgba(0, 0, 0, 0.05)',
                      padding: '5px 5px',
                      borderRadius: '8px',
                      color: 'white',
                      display: 'flex',
                      alignItems: 'center',
                      fontSize: '12px',
                      color: '#000000',
                      fontWeight: 'bold',
                    }}>
                      <EuiIcon type={headphone_icon} size="s" style={{ marginRight: '5px' }} /> 
                      {formatPlayCount(station.playcount)}
                    </div>
                    <EuiIcon
                      type="play"
                      color="ghost"
                      size="l"
                      style={{
                        position: 'absolute',
                        top: '70%',
                        left: '65%',
                        transform: 'translate(-50%, -50%)',
                        background: 'rgba(0, 0, 0, 0.3)',
                        borderRadius: '50%',
                        padding: '5px',
                      }}
                    />
                    </div>
                    <EuiText size="s">
                      <p style={{ margin: '0', marginTop: '0px' }}>{station.name}</p>
                    </EuiText>
                  </div>
                ))}
              </div>
            </EuiFlexGroup>
          )}
        </EuiPanel>
      </EuiFlexItem>
      {/* 渲染 PlaylistModal */}
      {showPlaylistModal && (
        <PlaylistModal
          userId={modalProps.userId}
          songId={modalProps.songId}
          videoURL={modalProps.videoURL}
          title={modalProps.title}
          type={modalProps.type}
          engineId={modalProps.engineId}
          label={modalProps.label}
          playlisttype={modalProps.playlisttype}
          closeModal={() => setShowModal(false)}
        />
      )}
    </EuiFlexGroup>
);
}

export default App;
