import React, { useEffect, useState } from 'react';
import YouTube from 'react-youtube';
import { useParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { EuiPanel, EuiFlexGroup, EuiFlexItem, EuiButtonIcon, EuiListGroup, EuiListGroupItem, EuiText, EuiButton, EuiIcon} from '@elastic/eui';
import logosmall from '../assets/CTBLogoCircle-Small.png';
import '../style.css';
import { TranslateLanguage } from './KeywordTranslator';
import axios from 'axios';

const apiURL = process.env.REACT_APP_MasterApiURL;
const apiKey = process.env.REACT_APP_APIKey;

const SharedFavoritePage = () => {
    const { playlistId } = useParams();
    const [playlist, setPlaylist] = useState(null);
    const [currentSongIndex, setCurrentSongIndex] = useState(0);
    const [isPanelVisible, setIsPanelVisible] = useState(true);
    const [currentResource, setCurrentResource] = useState('');
    const navigate = useNavigate();
    const [isPortrait, setIsPortrait] = useState(window.innerHeight > window.innerWidth);

    function fetchSharedPlaylist() {
        const shareCode = window.location.pathname.split("/").pop(); // 从 URL 中提取分享代码
        axios.get(`/api/playlists/sharedplaylist/${shareCode}`, {
            headers: {}  // 覆盖全局拦截器中的 Authorization 头
          })
            .then(response => {
                const data = response.data;
                setPlaylist(data);
            })
            .catch(error => console.error('加载分享的歌单时出错:', error));
    }

    useEffect(() => {
        const handleResize = () => {
          setIsPortrait(window.innerHeight > window.innerWidth);
        };
    
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
      }, []);

    const panelStyle = {
        position: 'sticky',
        top: 0,
        zIndex: 100,
        height: isPanelVisible 
        ? (isPortrait ? '45vh' : '80vh') // When panel is visible
        : (isPortrait ? '80vh' : '80vh') // When panel is not visible
      };

    useEffect(() => {
        fetchSharedPlaylist();
    }, [playlistId]);

    const opts = {
        height: '100%',
        width: '100%',
        playerVars: {
            autoplay: 1,
            playsinline: 1,
            enablejsapi: 1,
            fullscreen: 1
        },
    };

    const togglePanel = () => {
        setIsPanelVisible(!isPanelVisible);
    };

    const handleSongEnd = () => {
        if (playlist && playlist.playlistResources) {
            const nextIndex = (currentSongIndex + 1) % playlist.playlistResources.length;
            setCurrentSongIndex(nextIndex);
        }
    };

    if (!playlist) {
        return <div>加载中...</div>;
    }

    const currentSong = playlist.playlistResources[currentSongIndex];
    const getTextAlignment = (resource) => {
        if (resource && resource.resourceType === '經文') { 
            return 'right'; // 如果是經文，文本靠右
        }
        return 'left'; // 其他情况文本靠左
    };

    function extractContent(stringHTML) {
        var content =""
        var span = document.createElement('span');
        span.innerHTML = stringHTML;
        var contextExtracted = span.getElementsByTagName("table")[0];
        if(contextExtracted){
          contextExtracted.getElementsByTagName("u")[0].remove();
          contextExtracted.querySelectorAll("span, p, strong")
          .forEach(el => el.textContent.trim() === "" && el.parentNode.removeChild(el));
          content = contextExtracted.innerHTML;  
        }
        
        return content;
      };
    const renderTextContent = (textResource) => {
        const textTitleStyle = {
            fontSize: '20px',
            lineHeight: '1.6',
            color: '#333',
            padding: '15px',
            whiteSpace: 'pre-wrap',
            overflowY: 'auto',
            textAlign: 'center',         
        };
        const textContentStyle = {
            fontSize: '18px',
            lineHeight: '1.6',
            color: '#333',
            padding: '15px',
            whiteSpace: 'pre-wrap',
            overflowY: 'auto',
            letterSpacing: '0.1em',
        };
        const textContainerStyle = {
            height: isPanelVisible 
            ? (isPortrait ? '35vh' : '70vh') // When panel is visible
            : (isPortrait ? '70vh' : '70vh'), // When panel is not visible
            overflow: 'auto',
            padding: '10px',
            border: '1px solid #ccc', // 可选：为内容添加边框
            borderRadius: '5px', // 可选：为内容添加圆角
            backgroundColor: '#f9f9f9', // 可选：为背景添加颜色
        };
        var results;
        if (textResource.engineID !== undefined && textResource.engineID !== null)
        {
            fetch(apiURL,{
                method:"POST",
                headers:{
                    "Content-Type":"application/json, charset=UTF-8",
                    "Authorization":  "Bearer search-" + apiKey
                },
                body:JSON.stringify({
                    query:"",
                    filters: {"any": {"id": textResource.engineID}  }, 
                    page:{size: 1000},
                    sort:{id: "asc"}
                })
            })
            .then(function(response){
                return(response.json())
            })
            .then(function(data){
                results = JSON.parse(JSON.stringify(data)); 
                if(results.results[0].doctype.raw === "禱告")
                    setCurrentResource(results.results[0].prayer.raw);
                else if(results.results[0].doctype.raw === "經文")
                    setCurrentResource(results.results[0].chapter_name.raw+" "+results.results[0].chapter_verse_number.raw);
                else if((results.results[0].doctype.raw === "拾穗") || (results.results[0].doctype.raw === "註解" || (results.results[0].doctype.raw === "例證") || (results.results[0].doctype.raw === "綱目"))){
                    var resultText = results.results[0].title.raw;
                    var resultLink = results.results[0].link.raw;
                    resultText = resultText.split("壹、內容綱要").join("<p>壹、內容綱要<p>")
                    resultText = resultText.split("貳、逐節詳解").join("<p>貳、逐節詳解<p>")
                    resultText = resultText.split("叁、靈訓要義").join("<p>叁、靈訓要義<p>")
                    resultText = resultText.split("〔呂振中譯〕").join("<br>〔呂振中譯〕<br>")
                    resultText = resultText.split("〔原文字義〕").join("<br>〔原文字義〕<br>")
                    resultText = resultText.split("〔文意註解〕").join("<br>〔文意註解〕<br>")
                    resultText = resultText.split("〔話中之光〕").join("<br>〔話中之光〕<br>")
                    resultText = resultText.split("【").join("<p>【")
                    resultText = resultText + "<p>。。。（前往原始網站查看更多）"
                    loadContentLocal(resultLink, resultText);     
                }
                else if(results.results[0].doctype.raw === "網路")
                    setCurrentResource(results.results[0].description.raw);  
                else
                    setCurrentResource(null);       
            }
      )
    }
    const loadContentLocal = async (filePath, resultText) => {
        filePath = filePath.replace("https://www.ccbiblestudy.org/New Testament","");
        filePath = filePath.replace("https://www.ccbiblestudy.org/Old Testament","");
        const finalFilePath = window.location.origin + '/ccbiblestudy' + filePath;

        await fetch(finalFilePath)
        .then(function(response){
            return(response.text())
        })
        .then(function(data){
            var results = data;
            var finalResult = extractContent(results);
            resultText = finalResult != "" ? finalResult : resultText;
            setCurrentResource(resultText);
        })
    }
    return (
        <div style={panelStyle}>
            <>
            {!(textResource.resourceType === '拾穗' || textResource.resourceType === '註解' || textResource.resourceType === '例證' || textResource.resourceType === '綱目') && (
                <>
                <div style={textTitleStyle}>
                    <span>{TranslateLanguage(localStorage.userLanguage, textResource.name)}</span>
                </div>
                 {(textResource.resourceType === '經文')&&(
                    <div style={{...textTitleStyle, textAlign: getTextAlignment(textResource) }}>
                        <EuiText>
                            <span>{TranslateLanguage(localStorage.userLanguage, currentResource)}</span>
                        </EuiText>
                    </div>
                )}
                {!(textResource.resourceType === '經文')&&(
                    <div style={textContainerStyle}>
                        <EuiText>
                            <span>{TranslateLanguage(localStorage.userLanguage, currentResource)}</span>
                        </EuiText>
                    </div>
                )}
                </>  
            )}
            {(textResource.resourceType === '拾穗' || textResource.resourceType === '註解' || textResource.resourceType === '例證' || textResource.resourceType === '綱目') && (
                <div style={textContainerStyle}>
                    <EuiText>
                        <span dangerouslySetInnerHTML={{__html: TranslateLanguage(localStorage.userLanguage, currentResource)}} />
                    </EuiText>
                </div>
            )}   
            </>        
            <div style={{ textAlign: 'right', marginTop: '10px' }}>
            {textResource.link && (
                <EuiButton 
                    size="s" 
                    fill 
                    onClick={() => window.open(textResource.link, '_blank')}
                    style={{ backgroundColor: '#0077c2', color: '#fff' }}
                >
                    {TranslateLanguage(localStorage.userLanguage, "鏈接來源")}
                </EuiButton>
            )}
            </div>
        </div>
        );
    };
    return (
        <EuiFlexGroup>
            {/* 左侧播放窗口 */}
            <EuiFlexItem grow={isPanelVisible ? 2 : 3} style={panelStyle}>
                <EuiPanel style={panelStyle}>
                    {currentSong ? (
                        currentSong.labels === "v" ? (
                        <div>
                            <h3 style={{ textAlign: 'center', margin: '10px 0' }}>{TranslateLanguage(localStorage.userLanguage, currentSong.name)}</h3>
                            <div style={{ position: 'relative', paddingBottom: '56.25%', height: 0 }}>
                                <YouTube
                                    id="ytPlayer"
                                    videoId={currentSong.resourceId}
                                    className="yt-iframe"
                                    containerClassName="yt-container"
                                    opts={opts}
                                    onEnd={handleSongEnd}  // 添加此行来处理播放结束事件
                                    style={{
                                        position: 'absolute',
                                        top: 0,
                                        left: 0,
                                        width: '100%',
                                        height: '100%',
                                    }}
                                />
                            </div>
                        </div>) : (
                                    renderTextContent(currentSong)  // 渲染文本内容
                                )
                    ) : (
                        <p>{TranslateLanguage(localStorage.userLanguage, "請點擊收藏項目播放")}</p>
                    )}
                </EuiPanel>
            </EuiFlexItem>

            {/* 面板切换按钮 */}
            <EuiFlexItem grow={false} style={{ zIndex: 1 }}>
                <EuiFlexGroup className="panel-toggle-button" direction="column" alignItems="center" justifyContent="center">
                    <EuiFlexItem grow={false}>
                        <EuiButtonIcon
                            iconType={isPanelVisible ? 'arrowRight' : 'arrowLeft'}
                            onClick={togglePanel}
                            aria-label="Toggle Playlist Panel"
                        />
                    </EuiFlexItem>
                </EuiFlexGroup>
            </EuiFlexItem>

            {/* 右侧歌单及歌曲显示 */}
            {isPanelVisible && (
                <EuiFlexItem grow={1} style={{ height: '100vh', overflowY: 'auto' }}>
                    <EuiPanel style={{ width: '100%', textAlign: 'center', zIndex: 1 }}> {/* 确保整个面板宽度一致 */}
                        {/* 顶部Logo和标题部分 */}
                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                                cursor: 'pointer',
                                marginBottom: '20px',
                                width: '100%'
                            }}
                            onClick={() => navigate('/')}
                        >
                            <img src={logosmall} alt="Logo" style={{ width: '50px', height: '50px', marginBottom: '10px' }} />
                            <h2 style={{ margin: 0, textAlign: 'center' }}>{playlist ? `${TranslateLanguage(localStorage.userLanguage, "收藏單名")}：${playlist.name}` : '分享的收藏单'}</h2>
                        </div>
                        {/* 收藏单和收藏項目列表部分 */}
                        <div style={{ width: '100%' }}>
                            <EuiListGroup flush>
                                {playlist.playlistResources.map((song, index) => (
                                    <EuiListGroupItem
                                        key={song.resourceId}
                                        className={`song-item ${currentSong && currentSong.resourceId === song.resourceId ? 'playing-song' : ''}`}
                                        onClick={() => setCurrentSongIndex(index)}
                                        label={`${TranslateLanguage(localStorage.userLanguage, song.resourceType)}: ${
                                            // song.name.length > 12 ? TranslateLanguage(localStorage.userLanguage, song.name.substring(0, 12)) + '...' : TranslateLanguage(localStorage.userLanguage, song.name)
                                            TranslateLanguage(localStorage.userLanguage, song.name)
                                        }`}
                                        icon={<EuiIcon type={song.labels === "v" ? "play" : "document"} /> }
                                    />
                                ))}
                            </EuiListGroup>
                        </div>
                    </EuiPanel>
                </EuiFlexItem>
            )}
        </EuiFlexGroup>
    );
};

export default SharedFavoritePage;
