import React, { useState, useEffect } from 'react';
import {
  EuiLoadingSpinner,
  EuiPanel,
  EuiText,
  EuiSelect,
  EuiButton,
  EuiFlexGroup,
  EuiFlexItem,
  EuiFieldText,
  EuiFormRow,
  EuiTextArea
} from '@elastic/eui';
import '../style.css';

const NetworkManagement = () => {
  const [songs, setSongs] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedSong, setSelectedSong] = useState(null);
  const [sortKey, setSortKey] = useState('title'); // 默认按标题排序
  const [resultMessage, setResultMessage] = useState(''); // 用于显示提示信息
  const [isNewSong, setIsNewSong] = useState(false);  // 用于判断是否是新增诗歌
  const [searchTerm, setSearchTerm] = useState(''); // 用于存储搜索关键词
  const [totalCount, setTotalCount] = useState(0);

  const fetchAllSongs = async () => {
    const apiURL = "https://364cbaf8eaa6414ba48bf0a6002764a7.ent-search.us-west-2.aws.cloud.es.io/api/as/v1/engines/ctb-master-chinese/search";
    const apiKey = "72ikvuebo7fp1sagg8j3majj";
    const pageSize = 1000;
    let currentPage = 1;
    let hasMoreData = true;

    let fetchedSongs = [];

    try {
      while (hasMoreData) {
        const response = await fetch(apiURL, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            "Authorization": "Bearer search-" + apiKey
          },
          body: JSON.stringify({
            query: "",
            filters: { "all": [{ "doctype": "網路" }] },
            page: { size: pageSize, current: currentPage },
            sort: { id: "asc" }
          })
        });

        if (response.ok) {
          const data = await response.json();
          const songs = data.results.map(song => {      
            return {
              id: song.id.raw || "",
              title: song.title.raw || "",
              link: song.link.raw || "",
              doctype: song.doctype.raw || "",
              origin: song.origin.raw || "",
              category: song.category?.raw || "",
              description: song.description?.raw || "",
              tag: song.tag.raw || "",
              verselist: song.verselist.raw || "",
              lastupdate: song.lastupdate?.raw || "",
            };
          });

          fetchedSongs = [...fetchedSongs, ...songs];

          if (songs.length < pageSize) {
            hasMoreData = false;
          } else {
            currentPage++;
          }
        } else {
          console.error("Error fetching songs: ", response.statusText);
          return;
        }
      }

      setSongs(fetchedSongs);
      console.log("All songs fetched:", fetchedSongs);
      setTotalCount(fetchedSongs.length);  // 更新总条数
    } catch (error) {
      console.error("Error while fetching songs: ", error);
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchAllSongs();
  }, []);


  // 提取ID中的数字部分
  const extractIdNumber = (id) => {
    const match = id.match(/\d+$/);  // 匹配最后的数字部分
    return match ? parseInt(match[0], 10) : id;
  };
  // 获取当前最大ID + 1
  const getNextId = () => {
    const maxId = songs.reduce((max, song) => {
      const idParts = song.id.split('|'); // 将ID按 "|" 分割
      const numericId = parseInt(idParts[1], 10); // 取得后面的数字部分
  
      // 比较获取最大ID
      return Math.max(max, numericId);
    }, 0);
  
    return maxId + 1;  // 返回最大ID + 1
  };

  // 根据搜索关键词过滤列表
  const filteredSongs = songs.filter((song) =>
    song.title.toLowerCase().includes(searchTerm.toLowerCase())
  );

  // 按位数排序
  const sortSongs = (songs, key) => {
    if (key === 'id') {
      return [...songs].sort((a, b) => {
        const idA = extractIdNumber(a.id);
        const idB = extractIdNumber(b.id);
        return idA - idB;
      });
    }
    // 如果是按title排序，直接按字母排序
    return [...songs].sort((a, b) => (a[key] > b[key] ? 1 : -1));
  };

  // 对过滤后的结果进行排序
  const sortedSongs = sortSongs(filteredSongs, sortKey);

  const handleSongSelect = (song) => {
      setSelectedSong(song); // 设置选中的歌曲
      setIsNewSong(false);  // 保存成功后，取消新增标识
      setResultMessage(''); // 清空 resultMessage
    };
  // 点击"新建诗歌"按钮时调用
  const handleNewSong = () => {
    const newSong = {
      id: getNextId().toString(),  // ID为最大值+1
      title: '',
      link: '',
      category: '',
      description: '',
      lastupdate: '',
      tag: '',
      origin: '',
      verselist: '',
    };
    setSelectedSong(newSong);  // 将新诗歌设置为选中的诗歌
    setIsNewSong(true);  // 设置为新增模式
    setResultMessage('');  // 清空结果提示
  };
  // 保存修改的函数
  async function handleSaveSong() {
      // 设置初始提示信息
      setResultMessage("Start updating song...");

        // 验证红框内的项目是否为空
      if (!selectedSong.title || !selectedSong.category || !selectedSong.description) {
        setResultMessage('Error: Title/category/description fields must be filled.');
        return;  // 阻止保存操作
      }

      // 验证 link 是否为有效 URL
      const urlPattern = new RegExp('^(https?:\\/\\/)?' + // 协议
        '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.?)+[a-z]{2,}|(\\d{1,3}\\.){3}\\d{1,3})' + // 域名或IP
        '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // 端口和路径
        '(\\?[;&a-z\\d%_.~+=-]*)?' + // 查询字符串
        '(\\#[-a-z\\d_]*)?$', 'i'); // 片段标识符

        if (selectedSong.link){
            if (!urlPattern.test(selectedSong.link)) {
                setResultMessage('Error: The Song Link must be a valid URL.');
                return;  // 阻止保存操作
            }
                    // 如果是新增的诗歌（通过 isNewSong 判断），检查 Song Link 是否已存在
            if (isNewSong) {
                const linkExists = songs.some(song => song.link === selectedSong.link);
                if (linkExists) {
                setResultMessage('Error: The Song Link already exists. Please enter a unique link.');
                return;  // 阻止保存操作
                }
            }
        }

      // 获取表单输入值（使用 EUI 组件的值获取方法）
      const title = selectedSong.title;
      const link = selectedSong.link;
      const songId = extractIdNumber(selectedSong.id);

      // 构建新的歌曲对象
      const newSong = {
          id: songId,
          doctype: "網路",
          title: title,
          link: link,
          category: selectedSong.category,
          description: selectedSong.description,  
          lastupdate: selectedSong.lastupdate,  
          tag: selectedSong.tag,
          verselist: selectedSong.verselist,
          origin: selectedSong.origin,
      };

      // 调用上传函数并处理响应
      const uploadSuccess = await uploadSong(newSong);

      // 更新提示信息
      if (uploadSuccess) {        
          newSong.id = "ctb-webresource|"+songId;
          setSelectedSong(newSong);  // 使用返回的最新数据更新当前选中歌曲
          setResultMessage("Song updated successfully!");
          setSongs((prevSongs) => {
            const songExists = prevSongs.some(song => song.id === newSong.id);
            if (!songExists) {
              return [...prevSongs, newSong];  // 如果不存在，添加新诗歌
            } else {
              // 如果已存在，替换更新后的歌曲
              return prevSongs.map(song => 
                song.id === newSong.id ? newSong : song
              );
            }
          });
          if (isNewSong){
            setTotalCount(totalCount+1);  // 更新总条数
          }
          setIsNewSong(false);  // 保存成功后，取消新增标识
      } else {
          setResultMessage("Song update failed.");
      }
  };

  async function uploadSong(song) {
      try {
          const response = await fetch("https://364cbaf8eaa6414ba48bf0a6002764a7.ent-search.us-west-2.aws.cloud.es.io/api/as/v1/engines/ctb-webresource/documents", {
              method: "POST",
              headers: {
                  "Content-Type": "application/json",
                  "Authorization": "Bearer private-ya3jv4gb7q7rrybjribdsud9"
              },
              body: JSON.stringify([song])  // 上传时将歌曲对象封装为数组
          });

          if (response.ok) {
              console.log("Song uploaded: ", await response.json());
              return true;  // 上传成功
          } else {
              console.error("Error uploading song: ", response.statusText);
              return false;  // 上传失败
          }
      } catch (error) {
          console.error("Error while uploading song: ", error);
          return false;  // 处理异常
      }
  }

  if (loading) {
    return (
      <div style={{ textAlign: 'center', marginTop: '20px' }}>
        <EuiLoadingSpinner size="xl" />
        <EuiText color="default" style={{ marginTop: '10px' }}>
          正在加载中...
        </EuiText>
      </div>
    );
  }

  return (
    <EuiFlexGroup>
      {/* 左侧歌曲列表 */}
      <EuiFlexItem grow={false} className="song-list-container">
        <EuiPanel paddingSize="s">
          <EuiFormRow label={`網路列表 共${totalCount}條`}>
            <EuiSelect
              options={[
                { value: 'title', text: '按标题排序' },
                { value: 'id', text: '按ID排序' }
              ]}
              value={sortKey}
              onChange={(e) => setSortKey(e.target.value)}
            />
          </EuiFormRow>

          {/* 搜索框 */}
          <input
            type="text"
            placeholder="搜索網路..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            style={{ marginBottom: '10px', padding: '5px', width: '100%' }}
          />

          <ul className="song-list">
            {/* {sortSongs(songs, sortKey).map((song) => ( */}
            {sortedSongs.map((song) => (
              <li key={song.id} onClick={() => handleSongSelect(song)}>
                {song.title} (ID: {song.id})
              </li>
            ))}
          </ul>
        </EuiPanel>
      </EuiFlexItem>

      {/* 右侧详细信息编辑 */}
      <EuiFlexItem>
        <EuiFlexGroup direction="column">
        {selectedSong ? (
          <EuiPanel paddingSize="l">
            <h3>编辑網路: {selectedSong.title}</h3>

            <EuiFlexGroup>
              <EuiFlexItem>
                <EuiFormRow label="WebResource ID">
                  <EuiFieldText value={extractIdNumber(selectedSong.id)} readOnly />
                </EuiFormRow>

                <EuiFormRow label="Title">
                  <EuiFieldText
                    value={selectedSong.title}
                    onChange={(e) => setSelectedSong({ ...selectedSong, title: e.target.value })}
                  />
                </EuiFormRow>

                <EuiFormRow label="Link">
                  <EuiFieldText
                    value={selectedSong.link}
                    append={
                      <EuiButton href={selectedSong.link} target="_blank">
                        Open Link
                      </EuiButton>
                    }
                    onChange={(e) => setSelectedSong({ ...selectedSong, link: e.target.value })}
                  />
                </EuiFormRow>

                <EuiFormRow label="Lastupdate">
                  <EuiFieldText
                    value={selectedSong.lastupdate}
                    onChange={(e) => setSelectedSong({ ...selectedSong, lastupdate: e.target.value })}
                  />
                </EuiFormRow>

                <EuiFormRow label="Description">
                  <EuiTextArea
                    value={selectedSong.description}
                    onChange={(e) => setSelectedSong({ ...selectedSong, description: e.target.value })}
                  />
                </EuiFormRow>

              </EuiFlexItem>

              <EuiFlexItem>
                 <EuiFormRow label="Category">
                  <EuiFieldText
                    value={selectedSong.category}
                    onChange={(e) => setSelectedSong({ ...selectedSong, category: e.target.value })}
                  />
                </EuiFormRow>

                <EuiFormRow label="Origin">
                  <EuiFieldText
                    value={selectedSong.origin}
                    onChange={(e) => setSelectedSong({ ...selectedSong, origin: e.target.value })}
                  />
                </EuiFormRow>

                <EuiFormRow label="Tags">
                  <EuiFieldText
                    value={selectedSong.tag}
                    onChange={(e) => setSelectedSong({ ...selectedSong, tag: e.target.value })}
                  />
                </EuiFormRow>

                <EuiFormRow label="Verselist">
                  <EuiTextArea
                    value={selectedSong.verselist}
                    onChange={(e) => setSelectedSong({ ...selectedSong, verselist: e.target.value })}
                  />
                </EuiFormRow>
              </EuiFlexItem>
            </EuiFlexGroup>

            {/* 显示 resultMessage 的位置 */}
            <div style={{ textAlign: 'center' }}>
              <EuiText color="danger" style={{ marginBottom: '10px' }}>{resultMessage}</EuiText>
              <EuiButton onClick={handleSaveSong}>保存修改</EuiButton>
            </div>
          </EuiPanel>
        ) : (
          <EuiText>请选择一個網路資源以查看详细信息。</EuiText>
        )}
          <EuiFlexItem>
            {/* 新建诗歌按钮 */}
            <div style={{ textAlign: 'center' }}>
              <EuiButton style={{ width: '100px' }} onClick={handleNewSong}>新建網路</EuiButton>
            </div>
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexItem>
    </EuiFlexGroup>
  );
};

export default NetworkManagement;