import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom'; // 引入 useNavigate hook
import { EuiPanel, EuiIcon,EuiFlexGroup, EuiFlexItem, EuiButton, EuiSelect, EuiButtonIcon, EuiListGroup, EuiListGroupItem, EuiModal, EuiModalHeader, EuiModalBody, EuiModalFooter, EuiFieldText, EuiTextArea,  EuiDragDropContext,
    EuiDroppable,
    EuiDraggable, 
    EuiButtonEmpty,
    copyToClipboard,
    EuiSwitch,
    euiDragDropReorder} from '@elastic/eui';
import {TranslateLanguage} from './KeywordTranslator';
import '../style.css';
import logosmall from '../assets/CTBLogoCircle-Small.png';
import RadioModal from "../components/RadioModal"
import axios from 'axios';
import { jwtDecode } from "jwt-decode";  // 正确的导入方式
import { useAlert } from './AlertProvider';
// import { isInputElement } from 'react-router-dom/dist/dom';

function PlaylistPageWithPlayer() {
    const [playlists, setPlaylists] = useState([]);
    const [selectedPlaylist, setSelectedPlaylist] = useState(null);
    const [songs, setSongs] = useState([]);
    const [currentSong, setCurrentSong] = useState({ id: '', name: '' });
    const [initSong, setInitSong] = useState(null);
    const [isEditModalVisible, setIsEditModalVisible] = useState(false);
    const [newPlaylistName, setNewPlaylistName] = useState('');
    const [isPanelVisible, setIsPanelVisible] = useState(true); // 控制右侧面板是否可见
    const [showCreateModal, setShowCreateModal] = useState(false);
    const [showCreateRadioModal, setShowCreateRadioModal] = useState(false);
    const [showPlaylistToRadioModal, setshowPlaylistToRadioModal] = useState(false);
    const [newPlaylistVisibility, setNewPlaylistVisibility] = useState('private');
    const [newPlaylistDescription, setNewPlaylistDescription] = useState('');
    const [newPlaylistTags, setNewPlaylistTags] = useState('');
    const [newPlaylistCategory, setNewPlaylistCategory] = useState('');
    const [expandedPlaylist, setExpandedPlaylist] = useState(null); // 用于记录当前展开的歌单
    const [playlistDetails, setPlaylistDetails] = useState(null); // 用于存储歌单详情数据
    const [shareLink, setShareLink] = useState("");
    const navigate = useNavigate(); // 使用 useNavigate 创建 navigate 函数
    const [isPortrait, setIsPortrait] = useState(window.innerHeight > window.innerWidth);
    const playerRef = useRef(null); // 用 useRef 来存储 player 实例
    const [ytApiReady, setYtApiReady] = useState(false);
    const currentSongRef = useRef(currentSong);   // 使用 useRef 存储最新的 currentSong
    const songsRef = useRef(songs);
    const [showHint, setShowHint] = useState(true); // 提示文字的显示状态
    const songtitleRef = useRef(null);
    const leftpanelRef = useRef(null);
    const showAlert = useAlert();
    const [isLoopEnabled, setIsLoopEnabled] = useState(false); // 控制循环播放的状态
    const isLoopEnabledRef = useRef(isLoopEnabled); 
    const [modalProps, setModalProps] = useState({
        userId: '',
        playlistId: '',
        playlisttype: ''
      });

    const isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;

    // 从 sessionStorage 获取 userId
    const user = JSON.parse(sessionStorage.getItem('user'));
    const userId = user ? user.id : null;
    const token = sessionStorage.getItem("token");  // 从 localStorage 中获取 token
    let userRole = "";
    if (token) {
        const decodedToken = jwtDecode(token);  // 解码 token
        // console.log(decodedToken);  // 检查 token 中的内容，包括角色信息
        userRole = decodedToken.role;  // 获取角色信息
    }

    useEffect(() => {
        // 根据是否为 iOS 设备设置提示文字的显示状态
        if (isIOS) {
          setShowHint(true);  // 如果是 iOS 设备，显示提示文字
        } else {
          setShowHint(false); // 如果不是 iOS 设备，隐藏提示文字
        }
        const script = document.createElement('script');
        script.src = "https://www.youtube.com/iframe_api";
        script.async = true;
        script.onload = () => {
        //   console.log("YouTube IFrame API script loaded");
      
          // Check if the YT object is already available
          if (window.YT && window.YT.Player) {
            // console.log("YT object is available, API is ready");
            setYtApiReady(true);
          }
        };
        document.body.appendChild(script);
      
        window.onYouTubeIframeAPIReady = () => {
        //   console.log("YouTube IFrame API is ready");
          setYtApiReady(true);
        };
      
        // Fallback if the API doesn't signal readiness
        setTimeout(() => {
          if (window.YT && window.YT.Player && !ytApiReady) {
            console.log("Fallback: YT object available, setting ytApiReady to true");
            setYtApiReady(true);
          }
        }, 5000); // Fallback after 5 seconds
      }, []);
    
      // 当 ytApiReady 和 currentSong 都准备好时初始化播放器
      useEffect(() => {
        if (ytApiReady && initSong && !playerRef.current) {
          playerRef.current = new YT.Player('youtube-player', {
            height: '80%',
            width: '90%',
            videoId: initSong.resourceId,
            playerVars: {
              'autoplay': isIOS ? 0 : 1, // iOS 不自动播放，其他设备自动播放
              'controls': 1, // 显示控制条
              'mute': isIOS ? 1 : 0, // iOS 静音，其他设备不静音
            },
            events: {
              'onStateChange': onPlayerStateChange,
              'onError': onPlayerError // 添加错误处理
            }
          });
        }
      }, [ytApiReady, initSong]);

    useEffect(() => {
        if (playerRef.current && currentSong) {
        //   console.log('Next Song (after update):', currentSong);
            // if (playerRef.current.getPlayerState && playerRef.current.getPlayerState() !== -1) {
            if (playerRef.current.getPlayerState) {
                playerRef.current.loadVideoById(currentSong.resourceId);
                if (isIOS){
                    playerRef.current.unMute(); // 解除静音
                }
            }
        }
      }, [currentSong]);

    // 同步更新 currentSongRef
    useEffect(() => {
        currentSongRef.current = currentSong;
    }, [currentSong]);

    useEffect(() => {
        songsRef.current = songs;
    }, [songs]);

    const SongItem = ({ song, currentSong, handleSongClick, handleDeleteSong }) => {
        const songItemRef = useRef(null);

        useEffect(() => {
            if (songItemRef.current && currentSong && currentSong.resourceId === song.resourceId) {
                songItemRef.current.scrollIntoView({
                    behavior: 'smooth',
                    block: 'center',
                });
            }
        }, [currentSong, song]);

        return (
            <div ref={songItemRef}>
            <EuiPanel
                hasShadow={false}
                paddingSize="none"
                color={currentSong.resourceId === song.resourceId ? "danger" : "none"}
                hasBorder={true}
            >
                <EuiFlexGroup wrap={false} alignItems="center" gutterSize="s" style={{ flexWrap: 'nowrap' }}>
                    <EuiFlexItem grow={false} style={{ flexShrink: 0, flexBasis: '20px', minWidth: '20px', maxWidth: '20px' }}>
                        <EuiIcon type="grab" />
                    </EuiFlexItem>
                    <EuiFlexItem grow={true} style={{ flexShrink: 1, flexBasis: '100%' }}>
                        <EuiListGroupItem
                            className={`song-item ${currentSong && currentSong.resourceId === song.resourceId ? 'playing-song' : ''}`}
                            style={{ zIndex: 1 }}
                            label={
                                <>
                                {isPortrait
                                    ? TranslateLanguage(localStorage.userLanguage, song.name)
                                    : (song.name.length > 12 ? TranslateLanguage(localStorage.userLanguage, song.name.substring(0, 12)) + '...' : TranslateLanguage(localStorage.userLanguage, song.name))}
                                </>
                            }
                            icon={
                                <>
                                    <EuiIcon
                                        type="cross"
                                        // className="delete-icon"
                                        onClick={(e) => {
                                        e.stopPropagation();
                                        handleDeleteSong(song);
                                        }}
                                        style={{ marginLeft: '0px', marginRight: '10px', cursor: 'pointer' }}
                                    />
                                    <EuiIcon type="play" style={{ marginRight: '5px' }} />
                                </>
                            }
                            onClick={(e) => {
                                e.preventDefault();
                                e.stopPropagation();
                                e.currentTarget.focus();  // 强制获取焦点
                                handleSongClick(song);
                            }}
                            // extraAction={{
                            //     iconType: 'trash',
                            //     onClick: (e) => {
                            //         e.stopPropagation();
                            //         handleDeleteSong(song);
                            //     },
                            //     'aria-label': '删除歌曲',
                            //     style: { marginLeft: 'auto', cursor: 'pointer' }
                            // }}
                        />
                    </EuiFlexItem>
                </EuiFlexGroup>
            </EuiPanel>
            </div>
        );
    };

    function setViewportDimensions() {
        let height = 44;
        let calwidth;
        let calheight;
        // if (songtitleRef.current) {
        //     height = songtitleRef.current.offsetHeight + 20;
        //     // console.log('Height of the h3 element:', height + 'px');
        // }
        calwidth = window.innerWidth - 30;
        calheight = window.innerHeight - height;
        if (calwidth*9/16 > calheight)
            document.documentElement.style.setProperty('--viewport-height', `${window.innerHeight - height}px`);
        else
            document.documentElement.style.setProperty('--viewport-height', `${calwidth*9/16}px`);
        document.documentElement.style.setProperty('--viewport-width', `${window.innerWidth - 20}px`);
        // document.documentElement.style.setProperty('--viewport-height', `${window.innerHeight - height}px`);
    }
    
    // 初始化时设置值
    setViewportDimensions();
    
    // 监听窗口大小变化事件
    window.addEventListener('resize', setViewportDimensions);    

    useEffect(() => {
        const handleResize = () => {
          setIsPortrait(window.innerHeight > window.innerWidth);
        };
    
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
      }, []);

    const onPlayerStateChange = (event) => {
        switch (event.data) {
          case YT.PlayerState.PLAYING:
            // setPlaying(true); // 更新状态为播放中
            if (isIOS){
                playerRef.current.unMute(); // 解除静音
              }
            break;
          case YT.PlayerState.PAUSED:
            // setPlaying(false); // 更新状态为暂停
            break;
          case YT.PlayerState.ENDED:
            handleSongEnd();
            break;
          default:
            break;
        }
      };
    
    // 处理错误事件
    function onPlayerError(event) {
        const errorCode = event.data;
        switch (errorCode) {
        case 2:
            showAlert(TranslateLanguage(localStorage.userLanguage, "視頻ID無效，請檢查視頻ID。"));
            break;
        case 5:
            showAlert(TranslateLanguage(localStorage.userLanguage, "視頻無法播放，可能是格式問題。"));
            break;
        case 100:
            showAlert(TranslateLanguage(localStorage.userLanguage, "視頻已被刪除或設為私人視頻。"));
            break;
        case 101:
        case 150:
            showAlert(TranslateLanguage(localStorage.userLanguage, "視頻不允許嵌入播放。"));
            break;
        default:
            showAlert(TranslateLanguage(localStorage.userLanguage, "視頻播放出錯，請嘗試其他視頻。"));
            break;
        }
    
        // 尝试播放下一首或其他逻辑
        // handleSongEnd();
    }

    useEffect(() => {
        axios.get(`/api/playlists`, {
            params: {
                userId: userId,
                type: 'song',
            },
        })
        .then(response => {
            // Axios 会自动将响应解析为 JSON 格式
            const data = response.data;  // 直接使用 response.data 获取数据
            setPlaylists(data);  // 将数据传递给你的组件状态
        })
        .catch(error => {
            console.error('Error fetching playlists:', error);
        });
    }, []);

    const handlePlaylistChange = (playlistId) => {
        if (expandedPlaylist === playlistId) {
            setExpandedPlaylist(null); // 如果点击的歌单已经展开，则合上
        } else {
            setSelectedPlaylist(playlistId);

            axios.get(`/api/playlists/${playlistId}/songs`)
            .then(response => {
                const sortedSongs = response.data.sort((a, b) => a.order - b.order); // 按 order 排序
                setSongs(sortedSongs);
                setInitSong(sortedSongs[0]);
                setExpandedPlaylist(playlistId); // 展开新的歌单
            })
            .catch(error => {
                console.error('Error fetching songs:', error);
            });
        }
    };
    //删除歌曲的处理函数
    const handleDeleteSong = (songId) => {
        if (window.confirm(TranslateLanguage(localStorage.userLanguage, "確定從歌單中刪除這首詩歌？"))) {
            axios.delete(`/api/playlists/${selectedPlaylist}/songs/${songId.resourceId}`)
            .then(response => {
                if (response.status === 200) {
                    // 删除成功后更新前端的歌曲列表
                    setSongs(songs.filter(song => song.resourceId !== songId.resourceId));
                    showAlert(TranslateLanguage(localStorage.userLanguage, "刪除成功"));
                } else {
                    showAlert(TranslateLanguage(localStorage.userLanguage, "刪除失敗，請重試"));
                }
            })
            .catch(error => {
                console.error("删除歌曲时出错:", error);
                showAlert(TranslateLanguage(localStorage.userLanguage, "刪除失敗，請重試"));
            });
        }
    };    
    // 当 playlists 加载完成后，自动选择第一个歌单并触发其点击事件
    useEffect(() => {
        if (playlists.length > 0) {
            handlePlaylistChange(playlists[0].id);  // 自动选择第一个歌单
        }
    }, [playlists]);

    // 当歌曲列表(songs)更新时，自动播放第一个歌单的第一首歌曲
    useEffect(() => {
        if (songs.length > 0) {
            setCurrentSong(songs[0]);
        }
    }, [songs]);

    useEffect(() => {
        const player = document.getElementById('youtube-player');
        if (player) {
          player.className = isPanelVisible ? 'playlist-player' : 'playlist-player-full';
        }
      }, [isPanelVisible]);

    const handleSongClick = (song) => {
        // 如果当前点击的歌曲和正在播放的歌曲相同且播放器已暂停
        if (currentSong === song) { 
            if (playerRef.current.getPlayerState() === 0){ // 0 播放結束
              playerRef.current.seekTo(0);  // 将视频进度设置到开头
              setTimeout(() => playerRef.current.playVideo(), 10); // 触发重新播放
            }
            else if (playerRef.current.getPlayerState() === 2){ // 2 表示暂停状态
              playerRef.current.playVideo();
            }
        }else {
            // 否则设置为当前歌曲并开始播放
            setCurrentSong(song);
        }
    };

    const handleEditClick = () => {
        if (selectedPlaylist) {
            axios.get(`/api/playlists/${selectedPlaylist}`)
            .then(response => {
                const data = response.data;
                setPlaylistDetails(data);
                setNewPlaylistName(data.name);
                setNewPlaylistVisibility(data.visibility);
                setNewPlaylistDescription(data.description);
                setNewPlaylistTags(data.tags);
                setNewPlaylistCategory(data.category);
                setIsEditModalVisible(true);
            })
            .catch(error => {
                console.error('Error fetching playlist details:', error);
            });
        }
    };    

    // 处理播放结束事件，自动播放下一首歌
    const handleSongEnd = () => {
        const currentIndex = songsRef.current.findIndex(song => song.resourceId === currentSongRef.current?.resourceId);
        
        // 检查是否有歌曲在播放
        if (currentIndex === -1) return; // currentSongRef.current 为空时直接返回，防止报错

        // 如果开启了循环播放
        if (isLoopEnabledRef.current) {
            const nextIndex = (currentIndex + 1) % songsRef.current.length; // 循环播放
            setCurrentSong(songsRef.current[nextIndex]);
            //如果只有一首詩歌，下一首和當前首index一樣
            if (nextIndex == currentIndex){
                playerRef.current.seekTo(0);  // 将视频进度设置到开头
                setTimeout(() => playerRef.current.playVideo(), 10); // 触发重新播放
            }
        } else {
            // 如果不是循环播放，且当前是最后一首，则停止播放
            // if (currentIndex < songsRef.current.length - 1) {
            //     const nextIndex = currentIndex + 1; // 播放下一首
            //     setCurrentSong(songsRef.current[nextIndex]);
            // } else {
            //     // 最后一首播放完后，停止播放
            //     // setCurrentSong(null); // 停止播放，防止为空时报错
            //     playerRef.current.pauseVideo();
            // }
            // 如果不是循环播放，则停止播放
            playerRef.current.pauseVideo();
        }
    };

    //保存修改後的歌單信息
    const handleSaveChanges = () => {
        const requestBody = {
            name: newPlaylistName,
            visibility: newPlaylistVisibility,
            description: newPlaylistDescription,
            tags: newPlaylistTags,
            category: newPlaylistCategory
        };
        axios.put(`/api/playlists/${selectedPlaylist}`, requestBody, {
            headers: {
                'Content-Type': 'application/json',
                // 不需要手动添加 Authorization 头，拦截器会自动处理
            }
        })
        .then(() => {
            setIsEditModalVisible(false);
            const updatedPlaylists = playlists.map(p => p.id === selectedPlaylist ? { ...p, ...requestBody } : p);
            setPlaylists(updatedPlaylists);
        })
        .catch(error => {
            console.error('Error updating playlist:', error);
        });
    };    

    // 打开新建歌单模态窗口
    const openCreatePlaylistModal = () => {
        setNewPlaylistName("");
        setNewPlaylistDescription("");
        setNewPlaylistTags("");
        setNewPlaylistCategory("");
        setShowCreateModal(true);
    };
    // 打开新建電台模态窗口
    const openCreateRadioModal = () => {
        setShowCreateRadioModal(true);
    };
    // 关闭新建歌单模态窗口
    const closeCreatePlaylistModal = () => {
        setShowCreateModal(false);
    };
    // 关闭新建電台模态窗口
    const closeCreateRadioModal = () => {
        setShowCreateRadioModal(false);
    };

    // 创建新歌单
    const handleCreatePlaylist = () => {
        const requestBody = {
            userId: user.id,
            user: user,
            name: newPlaylistName,
            type: 'song',
            visibility: newPlaylistVisibility,
            description: newPlaylistDescription,
            tags: newPlaylistTags,
            category: newPlaylistCategory
        };

        axios.post(`/api/playlists/create`, requestBody, {
            headers: {
                'Content-Type': 'application/json',
            }
        })
        .then(response => {
            const data = response.data;
            // console.log('Playlist created:', data);
            showAlert(TranslateLanguage(localStorage.userLanguage, "歌單創建成功"));
            closeCreatePlaylistModal();
            
            // 更新歌单列表
            setPlaylists([...playlists, { id: data.playlistId, name: newPlaylistName }]);
        })
        .catch(error => {
            console.error('Error creating playlist:', error);
        });
    };
    // 创建新電台
    const handleCreateRadio = () => {
        const requestBody = {
            userId: user.id,
            user: user,
            name: newPlaylistName,
            type: 'radio',
            visibility: newPlaylistVisibility,
            description: newPlaylistDescription,
            tags: newPlaylistTags,
            category: newPlaylistCategory
        };
        axios.post(`/api/playlists/create`, requestBody, {
            headers: {
                'Content-Type': 'application/json',
                // 不需要手动添加 Authorization 头，拦截器会自动处理
            }
        })
        .then(response => {
            const data = response.data;
            // console.log('Playlist created:', data);
            showAlert(TranslateLanguage(localStorage.userLanguage, "電台創建成功"));
            closeCreateRadioModal();
        
            // 如果你想更新歌单列表，可以解开下面的注释
            // setPlaylists([...playlists, { id: data.playlistId, name: newPlaylistName }]);
        })
        .catch(error => {
            console.error('Error creating playlist:', error);
        });
    };
    //生成分享鏈接
    const handleSharePlaylistClick = () => {
        const urlPrefix = window.location.origin;
        axios.get(`/api/playlists/share/${selectedPlaylist}`)
        .then(response => {
            const data = response.data;
            if (data.shareLink) {
                let shareLink = `${urlPrefix}${data.shareLink}`;
                setShareLink(shareLink);
            } else {
                showAlert(TranslateLanguage(localStorage.userLanguage, "無法生成分享鏈接，可能歌單是私有的。"));
            }
        })
        .catch(error => {
            console.error("生成分享链接时出错:", error);
            showAlert(TranslateLanguage(localStorage.userLanguage, "生成分享鏈接失敗，請點擊“詳情”設置為“公開”！"));
        });
    };
    //將歌單存為電台
    const handleSaveAsRadioClick = () => {
        setModalProps({
            userId: user.id, // 假设 user 对象里有 id 字段
            playlistId: selectedPlaylist,
            playlisttype: 'radio'
          });
        setshowPlaylistToRadioModal(true);
    };
    //刪除歌單
    const handleDeletePlaylistClick = () => {
        if (window.confirm(TranslateLanguage(localStorage.userLanguage, "確定刪除這個歌單？"))) {
            axios.delete(`/api/playlists/${selectedPlaylist}`)
            .then(response => {
                if (response.status === 200) {
                    // 从本地状态中移除被删除的歌单
                    setPlaylists(playlists.filter(playlist => playlist.id !== selectedPlaylist));
                    showAlert(TranslateLanguage(localStorage.userLanguage, "歌單刪除成功"));
                } else {
                    showAlert(TranslateLanguage(localStorage.userLanguage, "刪除失敗，請重試"));
                }
            })
            .catch(error => {
                console.error("删除歌单时出错:", error);
                showAlert(TranslateLanguage(localStorage.userLanguage, "刪除失敗，請重試"));
            });
        }
    };    

    const copyShareLink =()=> {
        copyToClipboard(shareLink);
        setShareLink(false)
      };

    const onDragEnd = ({ source, destination }) => {
        if (source && destination) {
          const reorderedSongs = euiDragDropReorder(songs, source.index, destination.index);
          setSongs(reorderedSongs);
            const songOrders = reorderedSongs.map((song, index) => ({
                songId: song.resourceId, 
                order: index + 1  // `index` 是从 0 开始的，因此我们加 1
            }));
        
            const payload = {
                playlistId: selectedPlaylist,
                songOrders: songOrders
            };

            axios.post(`/api/playlists/${selectedPlaylist}/reorder`, payload, {
                headers: {
                    'Content-Type': 'application/json',
                    // 不需要手动添加 Authorization 头，拦截器会自动处理
                }
            })
            .then(response => {
                if (response.status !== 200) {
                    throw new Error(`HTTP error! status: ${response.status}`);
                }
                setSongs([...reorderedSongs]);
            })
            .catch(error => {
                console.error('Error saving song order:', error);
            });
        }
      };

    function enterFullscreen() {
        const playerElement = playerRef.current.getIframe();
        if (isIOS) {
            showAlert(TranslateLanguage(localStorage.userLanguage, "在 iOS 設備上，請使用播放器右下角的全屏按鈕進入全屏模式。"));
        }
        else if (playerElement.requestFullscreen) {
            playerElement.requestFullscreen();
        } else if (playerElement.mozRequestFullScreen) { // Firefox
            playerElement.mozRequestFullScreen();
        } else if (playerElement.webkitRequestFullscreen) { // Chrome, Safari and Opera
            playerElement.webkitRequestFullscreen();
        } else if (playerElement.msRequestFullscreen) { // IE/Edge
            playerElement.msRequestFullscreen();
        }
    }
    
    const togglePanel = () => {
        enterFullscreen();
        // if (isPortrait && isPanelVisible){
        //     enterFullscreen();
        // }
        // else{
        //     setIsPanelVisible(!isPanelVisible);
        // }    
    };

    useEffect(() => {
        isLoopEnabledRef.current = isLoopEnabled;  // 每次状态更新时更新 ref
      }, [isLoopEnabled]);

    const handleLoopToggle = (e) => {
        // console.log('Loop toggled, new state:', e.target.checked);
        setIsLoopEnabled(e.target.checked);
    };

    return (
        <EuiFlexGroup className="responsive-flex-group">
            {/* 左侧播放窗口 */}
            <EuiFlexItem ref={leftpanelRef} grow={isPanelVisible ? 2 : 3} style={{ position: 'sticky', top: 0,  zIndex: 100 }} className={isPanelVisible ? "left_video_item":  "left_video_item_full"}>
                <EuiPanel 
                    style={{
                        display: 'flex',
                        flexDirection: 'column', // 垂直排列子元素
                        justifyContent: 'center', // 水平居中对齐
                        alignItems: 'center', // 子元素在垂直方向居中
                        padding: '5px', // 可根据需要调整面板的内边距
                        }}  
                >
                    {currentSong ? (
                        <>
                            {/* <h3 ref={songtitleRef} style={{ textAlign: 'center', margin: '10px 0' }}>{TranslateLanguage(localStorage.userLanguage, currentSong.name)}</h3>
                            <EuiButtonEmpty iconType={isPanelVisible ? 'arrowRight' : 'arrowLeft'} onClick={togglePanel}>
                                 {isPortrait? TranslateLanguage(localStorage.userLanguage, '全屏'):''}
                            </EuiButtonEmpty> */}
                            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', margin: '10px 0', width: '100%' }}>
                            {/* 歌曲标题 */}
                            <h3 ref={songtitleRef} style={{ flex: 1, textAlign: 'center', margin: 0 }}>
                                {TranslateLanguage(localStorage.userLanguage, currentSong.name)}
                            </h3>
                            
                            {/* 按钮 */}
                            <EuiButtonEmpty iconType={isPanelVisible ? 'arrowRight' : 'arrowLeft'} onClick={togglePanel} style={{ marginLeft: 'auto' }}>
                                {/* {isPortrait ? TranslateLanguage(localStorage.userLanguage, '全屏') : ''} */}
                                {TranslateLanguage(localStorage.userLanguage, '全屏')}
                            </EuiButtonEmpty>
                            </div>
                            <div id="youtube-player" className = 'playlist-player'></div>
                            <div style={{ 
                                display: 'flex', 
                                marginTop: '10px',
                                justifyContent: 'space-around', 
                                alignItems: 'center', 
                                width: '100%'  // 让按钮组与播放器同宽 
                                }}>
                                    <EuiSwitch
                                        label={TranslateLanguage(localStorage.userLanguage, "循環播放")}
                                        checked={isLoopEnabled}
                                        onChange={handleLoopToggle} // 切换循环播放开关
                                    />
                            </div>
                        </>
                    ) : (
                        <p>{TranslateLanguage(localStorage.userLanguage, "請點擊歌曲播放")}</p>
                    )}
                </EuiPanel>
            </EuiFlexItem>

            {/* 面板切换按钮 */}
            {/* <EuiFlexItem grow={false} className='center_toggle_button'>
                <EuiFlexGroup className="panel-toggle-button" direction="column" alignItems="center" justifyContent="center">
                    <EuiFlexItem grow={false}>
                        { <EuiButtonIcon
                            iconType={isPanelVisible ? 'arrowRight' : 'arrowLeft'}
                            onClick={togglePanel}
                            label={TranslateLanguage(localStorage.userLanguage, '全屏')}
                            aria-label="Toggle Playlist Panel"
                        /> }
                        <EuiButtonEmpty iconType={isPanelVisible ? 'arrowRight' : 'arrowLeft'} onClick={togglePanel}>
                            {isPortrait? TranslateLanguage(localStorage.userLanguage, '全屏'):''}
                        </EuiButtonEmpty>
                    </EuiFlexItem>
                </EuiFlexGroup>
            </EuiFlexItem> */}

            {/* 右侧歌单及歌曲显示 */}
            {isPanelVisible && (
                <EuiFlexItem grow={1}  className="rigth_song_info" style={{ height: '100vh', overflowY: 'auto' }}>
                    <EuiPanel style={{ width: '100%', textAlign: 'center', zIndex: 1, display: 'flex', flexDirection: 'column'}}> {/* 确保整个面板宽度一致 */}
                        {/* 顶部Logo和标题部分 */}
                        <div 
                            style={{ 
                                display: 'flex', 
                                flexDirection: 'column', 
                                alignItems: 'center', 
                                cursor: 'pointer', 
                                marginBottom: '15px', 
                                width: '100%' 
                            }} 
                            onClick={() => navigate('/')}
                        >
                            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                <img src={logosmall} alt="Logo" style={{ width: '50px', height: '50px', marginBottom: '10px' }} />
                                <h2 style={{ margin: 10, textAlign: 'center' }}>{user ? `${TranslateLanguage(localStorage.userLanguage, user.username)}的歌单` : '我的歌单'}</h2>
                            </div>
                        </div>
                        {/* 歌单和歌曲列表部分 */}
                        <div style={{ width: '100%' }}>
                            <EuiListGroup flush >
                            {playlists.map(playlist => (
                                <div key={playlist.id}>
                                        <EuiFlexGroup alignItems="center" gutterSize="s" direction="row">
                                            <EuiFlexItem grow={1}>
                                                <EuiListGroupItem
                                                    className={`playlist-item ${selectedPlaylist === playlist.id ? 'selected-playlist' : ''}`}
                                                    label={TranslateLanguage(localStorage.userLanguage, playlist.name)}
                                                    onClick={() => handlePlaylistChange(playlist.id)}
                                                    iconType={expandedPlaylist === playlist.id ? 'arrowDown' : 'arrowRight'}
                                                />
                                            </EuiFlexItem>
                                        </EuiFlexGroup>
                                        {expandedPlaylist === playlist.id && (
                                            <EuiDragDropContext onDragEnd={onDragEnd}>
                                                <EuiDroppable droppableId="DROPPABLE_AREA" spacing="none">
                                                    {songs.map((song, index) => (
                                                        <EuiDraggable spacing="none" key={song.resourceId} index={index} draggableId={song.name} paddingSize="none" hasBorder={true}>
                                                            {(provided, state) => (
                                                                 <SongItem
                                                                 song={song}
                                                                 currentSong={currentSong}
                                                                 handleSongClick={handleSongClick}
                                                                 handleDeleteSong={handleDeleteSong}
                                                             />
                                                            )}
                                                        </EuiDraggable>
                                                    ))}
                                                </EuiDroppable>
                                            </EuiDragDropContext>
                                        )}
                                    </div>
                                ))}
                            </EuiListGroup>

                            <div style={{ width: '100%', padding: '5px', display: 'flex', justifyContent: 'space-evenly' }}>
                                {selectedPlaylist && (
                                    <>
                                    <EuiButtonEmpty iconType="advancedSettingsApp" size="s" onClick={handleEditClick}>
                                        {TranslateLanguage(localStorage.userLanguage, "歌單詳情")}
                                    </EuiButtonEmpty>
                                    <EuiButtonEmpty iconType="share" size="s" onClick={handleSharePlaylistClick}>
                                        {TranslateLanguage(localStorage.userLanguage, "分享歌單")}
                                    </EuiButtonEmpty>
                                    </>
                                )}
                              </div>
                              <div style={{ width: '100%', padding: '5px', display: 'flex', justifyContent: 'space-evenly' }}>
                                {selectedPlaylist && (
                                    <>
                                    <EuiButtonEmpty iconType="trash" size="s" onClick={handleDeletePlaylistClick}>
                                        {TranslateLanguage(localStorage.userLanguage, "刪除歌單")}
                                    </EuiButtonEmpty>
                                    </>
                                )}
                                <EuiButtonEmpty iconType="listAdd" size="s" onClick={openCreatePlaylistModal}>
                                  {TranslateLanguage(localStorage.userLanguage, "新建歌單")}
                                </EuiButtonEmpty>
                              </div>
                              <div style={{ width: '100%', padding: '5px', display: 'flex', justifyContent: 'space-evenly' }}>
                                {selectedPlaylist && (
                                    <>
                                    { userRole === "Admin" && (
                                        <EuiButtonEmpty iconType="save" size="s" onClick={handleSaveAsRadioClick}>
                                            {TranslateLanguage(localStorage.userLanguage, "存為電台")}
                                        </EuiButtonEmpty>
                                    )}
                                    </>
                                )}
                                { userRole === "Admin" && (
                                    <EuiButtonEmpty iconType="listAdd" size="s" onClick={() => navigate('/radioeditpage')}>
                                        {TranslateLanguage(localStorage.userLanguage, "編輯電台")}
                                    </EuiButtonEmpty>
                                )}
                              </div>                                                                                                              
                        </div>
                    </EuiPanel>
                </EuiFlexItem>
            )}

            {/* 如果生成了分享链接，显示链接和复制按钮 */}
            {shareLink && (
                <EuiModal
                    onClose={() => setShareLink(false)}
                    style={{
                        maxWidth: '90%',
                        height: '90%',
                        minHeight: '50vh',
                        margin: 'auto'
                    }}
                >
                    <EuiModalHeader>
                        <h2 style={{ textAlign: 'center', width: '100%' }}>{TranslateLanguage(localStorage.userLanguage, "分享鏈接")}</h2>
                    </EuiModalHeader>
                
                    <EuiModalBody style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', textAlign: 'center' }}>
                        <div style={{ overflowX: 'auto', wordBreak: 'break-all', maxWidth: '100%' }}>
                            <p style={{ whiteSpace: 'pre-wrap', wordWrap: 'break-word' }}>{shareLink}</p>
                        </div>
                    </EuiModalBody>
                
                    <EuiModalFooter style={{ display: 'flex'}}>
                        <EuiButton fill onClick={() => copyShareLink(shareLink)}>{TranslateLanguage(localStorage.userLanguage, "複製鏈接")}</EuiButton>
                        <EuiButton onClick={() => setShareLink(false)} color="danger">{TranslateLanguage(localStorage.userLanguage, "關閉")}</EuiButton>
                    </EuiModalFooter>
                </EuiModal>            
                )}

            {/* 编辑歌单模态窗口 */}
            {isEditModalVisible && (
                <EuiModal onClose={() => setIsEditModalVisible(false)}>
                    <EuiModalHeader>
                        <h2>{TranslateLanguage(localStorage.userLanguage, "歌單詳情")}</h2>
                    </EuiModalHeader>
                    <EuiModalBody>
                        <EuiFieldText
                            placeholder={TranslateLanguage(localStorage.userLanguage, "歌單名稱")}
                            value={TranslateLanguage(localStorage.userLanguage, newPlaylistName)}
                            onChange={e => setNewPlaylistName(e.target.value)}
                            required
                            aria-label="歌單名稱"  // 增加無障礙支持
                            isInvalid={!newPlaylistName.trim()} // 若名称为空，设置为无效状态
                        />
                        <EuiSelect
                            options={[
                                { value: 'public', text: TranslateLanguage(localStorage.userLanguage, "公開")},
                                { value: 'private', text: TranslateLanguage(localStorage.userLanguage, "私有")},
                                { value: 'shareable', text: TranslateLanguage(localStorage.userLanguage, "可分享但不加入搜索列表")}
                            ]}
                            value={newPlaylistVisibility}
                            onChange={(e) => setNewPlaylistVisibility(e.target.value)}
                        />
                        <EuiTextArea
                            placeholder={TranslateLanguage(localStorage.userLanguage, "歌單描述")}
                            value={newPlaylistDescription}
                            onChange={(e) => setNewPlaylistDescription(e.target.value)}
                        />
                        <EuiFieldText
                            placeholder={TranslateLanguage(localStorage.userLanguage, "標籤(用逗號分隔)")}
                            value={newPlaylistTags}
                            onChange={(e) => setNewPlaylistTags(e.target.value)}
                        />
                        <EuiFieldText
                            placeholder={TranslateLanguage(localStorage.userLanguage, "分類")}
                            value={newPlaylistCategory}
                            onChange={(e) => setNewPlaylistCategory(e.target.value)}
                        />
                    </EuiModalBody>
                    <EuiModalFooter>
                        <EuiButton 
                        onClick={() => {
                            if (newPlaylistName.trim()) {
                                handleSaveChanges();
                            } else {
                                showAlert(TranslateLanguage(localStorage.userLanguage, "歌單名稱為必填項"));
                            }
                        }} 
                        fill
                    >
                        {TranslateLanguage(localStorage.userLanguage, "保存更改")}
                    </EuiButton>
                        <EuiButton onClick={() => setIsEditModalVisible(false)} color="danger">{TranslateLanguage(localStorage.userLanguage, "取消")}</EuiButton>                  
                    </EuiModalFooter>
                </EuiModal>
            )}


            {/* 新建歌单模态窗口 */}
            {showCreateModal && (
            <EuiModal onClose={closeCreatePlaylistModal}>
                <EuiModalHeader>
                    <h2>{TranslateLanguage(localStorage.userLanguage, "創建新的歌單")}</h2>
                </EuiModalHeader>
                <EuiModalBody>
                    <EuiFieldText
                        placeholder={TranslateLanguage(localStorage.userLanguage, "歌單名稱")}
                        value={newPlaylistName}
                        onChange={e => setNewPlaylistName(e.target.value)}
                        required
                        aria-label="歌單名稱"  // 增加無障礙支持
                        isInvalid={!newPlaylistName.trim()} // 若名称为空，设置为无效状态
                    />
                    <EuiSelect
                        options={[
                            { value: 'public', text: TranslateLanguage(localStorage.userLanguage, "公開")},
                            { value: 'private', text: TranslateLanguage(localStorage.userLanguage, "私有")},
                            { value: 'shareable', text: TranslateLanguage(localStorage.userLanguage, "可分享但不加入搜索列表")}
                        ]}
                        value={newPlaylistVisibility}
                        onChange={e => setNewPlaylistVisibility(e.target.value)}
                    />
                    <EuiTextArea
                        placeholder={TranslateLanguage(localStorage.userLanguage, "歌單描述")}
                        value={newPlaylistDescription}
                        onChange={e => setNewPlaylistDescription(e.target.value)}
                    />
                    <EuiFieldText
                        placeholder={TranslateLanguage(localStorage.userLanguage, "標籤(用逗號分隔)")}
                        value={newPlaylistTags}
                        onChange={e => setNewPlaylistTags(e.target.value)}
                    />
                    <EuiFieldText
                        placeholder={TranslateLanguage(localStorage.userLanguage, "分類")}
                        value={newPlaylistCategory}
                        onChange={e => setNewPlaylistCategory(e.target.value)}
                    />
                </EuiModalBody>
                <EuiModalFooter>
                    <EuiButton 
                        onClick={() => {
                            if (newPlaylistName.trim()) {
                                handleCreatePlaylist();
                            } else {
                                showAlert(TranslateLanguage(localStorage.userLanguage, "歌單名稱為必填項"));
                            }
                        }} 
                        fill
                    >
                        {TranslateLanguage(localStorage.userLanguage, "創建")}
                    </EuiButton>
                    <EuiButton onClick={closeCreatePlaylistModal} color="danger">{TranslateLanguage(localStorage.userLanguage, "取消")}</EuiButton>
                </EuiModalFooter>
            </EuiModal>        
            )}
            {/* 渲染 RadioModal */}
            {showPlaylistToRadioModal && (
                <RadioModal
                  userId={modalProps.userId}
                  playlistId={modalProps.playlistId}
                  playlisttype={modalProps.playlisttype}
                  closeModal={() => setshowPlaylistToRadioModal(false)}
                />
              )}
            {/* 新建電台模态窗口 */}
            {showCreateRadioModal && (
            <EuiModal onClose={closeCreateRadioModal}>
                <EuiModalHeader>
                    <h2>{TranslateLanguage(localStorage.userLanguage, "創建新的電台")}</h2>
                </EuiModalHeader>
                <EuiModalBody>
                    <EuiFieldText
                        placeholder={TranslateLanguage(localStorage.userLanguage, "電台名稱")}
                        value={newPlaylistName}
                        onChange={e => setNewPlaylistName(e.target.value)}
                        required
                        aria-label="電台名稱"  // 增加無障礙支持
                        isInvalid={!newPlaylistName.trim()} // 若名称为空，设置为无效状态
                    />
                    <EuiSelect
                        options={[
                            { value: 'public', text: TranslateLanguage(localStorage.userLanguage, "公開")},
                            { value: 'private', text: TranslateLanguage(localStorage.userLanguage, "私有")},
                            { value: 'shareable', text: TranslateLanguage(localStorage.userLanguage, "可分享但不加入搜索列表")}
                        ]}
                        value={newPlaylistVisibility}
                        onChange={e => setNewPlaylistVisibility(e.target.value)}
                    />
                    <EuiTextArea
                        placeholder={TranslateLanguage(localStorage.userLanguage, "電台描述")}
                        value={newPlaylistDescription}
                        onChange={e => setNewPlaylistDescription(e.target.value)}
                    />
                    <EuiFieldText
                        placeholder={TranslateLanguage(localStorage.userLanguage, "標籤(用逗號分隔)")}
                        value={newPlaylistTags}
                        onChange={e => setNewPlaylistTags(e.target.value)}
                    />
                    <EuiFieldText
                        placeholder={TranslateLanguage(localStorage.userLanguage, "分類")}
                        value={newPlaylistCategory}
                        onChange={e => setNewPlaylistCategory(e.target.value)}
                    />
                </EuiModalBody>
                <EuiModalFooter>
                    <EuiButton 
                        onClick={() => {
                            if (newPlaylistName.trim()) {
                                handleCreateRadio();
                            } else {
                                showAlert(TranslateLanguage(localStorage.userLanguage, "電台名稱為必填項"));
                            }
                        }} 
                        fill
                    >
                        {TranslateLanguage(localStorage.userLanguage, "創建")}
                    </EuiButton>
                    <EuiButton onClick={closeCreateRadioModal} color="danger">{TranslateLanguage(localStorage.userLanguage, "取消")}</EuiButton>
                </EuiModalFooter>
            </EuiModal>        
            )}
        </EuiFlexGroup>
    );
}

export default PlaylistPageWithPlayer;
